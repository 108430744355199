import Mousetrap, {type ExtendedKeyboardEvent} from "mousetrap";
import {blurEverything} from "./util";
import {type Child, type Id} from "../common/domain/data";
import {writeFragment} from "./fragment";
import {toUrlRepr} from "../common/urlpath";
import {TOP_SEARCH_PLACEHOLDER} from "./view/view";
import {xpathContainsClass} from "../common/util";
import {NEW_CHILD_TITLE_PLACEHOLDER} from "../common/config";

Mousetrap.prototype.stopCallback = (e: ExtendedKeyboardEvent, element: HTMLElement, combo: string) => {
    // AMP: this is the only change I made to the default
    if (combo === 'esc') {
        return false;
    }

    // if the element has the class "mousetrap" then no need to stop
    if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
        return false;
    }

    // stop for input, select, and textarea
    return element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA' || (element.contentEditable && element.contentEditable === 'true');
};

const MAX_TRIES = 3;
const WAIT_FOR_ASYNC_ACTION_MS = 50;

export const keymap: Map<string[], {desc: string, action: (e: Event) => void }> = new Map();
const addKey = (keys: string[], desc: string, action: (e: Event) => void) => {
    keymap.set(keys, {desc, action});
};

addKey(
    ['esc'],
    "Deselect everything",
    () => {
        blurEverything();
        const selected = document.querySelector<HTMLElement>("#keyboard-selected");
        if (selected) {
            selected.id = "";
        }
    }
);
addKey(
    ['?'],
    "Show this help",
    e => {
        writeFragment(`#${toUrlRepr({type: "Help", component: "keyboard"})}`);
        e.preventDefault();
    }
);
addKey(
    ['/'],
    "Search",
    e => {
        void focusByPlaceholder(TOP_SEARCH_PLACEHOLDER);
        e.preventDefault();
    }
);
addKey(
    ['t'],
    "Edit focus title",
    e => {
        document.querySelector(".focus .title")?.dispatchEvent(mouse("dblclick"));
        e.preventDefault();
    }
);
addKey(
    ['d'],
    "Edit focus detail",
    e => {
        xp(`//*[starts-with(text(), "(double click here to ") and contains(text(), " detail)")]`)
            .then(el => el[0]?.dispatchEvent(mouse("dblclick")))
            .then(() => (document.querySelector(`.focus textarea[name=detail]`) as HTMLElement | null)?.focus());
        e.preventDefault();
    }
);
addKey(
    ['a', 'A'],
    "Add a new item",
    e => {
        focusByPlaceholder(NEW_CHILD_TITLE_PLACEHOLDER);
        e.preventDefault();
    }
);
addKey(
    ['0', 'H'],
    "Back to root",
    e => {
        writeFragment(`#${toUrlRepr({type: "Graph", breadcrumbs: []})}`)
        e.preventDefault();
    }
);

for (let i = 0; i < 9; i++) {
    addKey(
        [(i + 1).toString()],
        `Go to child ${(i + 1)}`,
        e => {
            void xp(`//a[${xpathContainsClass('child-title')}]`).then(els => els[i]?.dispatchEvent(mouse("click")));
            e.preventDefault();
        }
    );
}

addKey(
    ['j'],
    "Select next child",
    e => selectChild(e, +1)
);
addKey(
    ['k'],
    "Select previous child",
    e => selectChild(e, -1)
);
addKey(
    ['L'],
    "Move child to bottom",
    e => move(e, "action-move-bottom")
);
addKey(
    ['H'],
    "Move child to top",
    e => move(e, "action-move-top")
);

addKey(
    ['J'],
    "Move child down",
    e => move(e, "action-move-down")
);
addKey(
    ['K'],
    "Move child up",
    e => move(e, "action-move-up")
);

addKey(
    ['D'],
    "Delete child",
    e => {
        const el = document.querySelector<HTMLElement>("#keyboard-selected button.action-delete");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);
addKey(
    ['T'],
    "Tag child",
    e => {
        const el = document.querySelector<HTMLElement>("#keyboard-selected button.action-tag");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);
addKey(
    ['C'],
    "Copy child",
    e => {
        const el = document.querySelector<HTMLElement>("#keyboard-selected button.action-copy");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);
addKey(
    ['M'],
    "Move child",
    e => {
        const el = document.querySelector<HTMLElement>("#keyboard-selected button.action-move");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);

addKey(
    ['x'],
    "Mark selected child done/not done",
    e => {
        const el = document.querySelector<HTMLElement>("#keyboard-selected input[type=checkbox]");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);
addKey(
    ['e'],
    "Edit selected child",
    e => {
        const anchorEl = document.querySelector<HTMLElement>("#keyboard-selected a.child-title");
        if (!anchorEl) return;

        anchorEl.dispatchEvent(mouse("dblclick"));

        setTimeout(() => {
            const inputEl = document.querySelector<HTMLElement>("#keyboard-selected input.child-title");
            inputEl?.focus();
        }, WAIT_FOR_ASYNC_ACTION_MS);
        e.preventDefault();
    }
);
addKey(
    ['enter', 'l'],
    "Move into selected child",
    e => {
        const el = document.querySelector<HTMLElement>("#keyboard-selected a.child-title");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);
addKey(
    ['h'],
    "Move up to parent",
    e => {
        const els = document.querySelectorAll<HTMLElement>(".breadcrumb-item a");
        if (els.length < 2) return;

        const el = els[els.length - 2] as HTMLElement;
        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);

addKey(
    ['y'],
    "Confirmation: yes",
    e => {
        const el = document.querySelector<HTMLElement>("button#confirmation-yes");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);
addKey(
    ['n'],
    "Confirmation: no",
    e => {
        const el = document.querySelector<HTMLElement>("button#confirmation-no");
        if (!el) return;

        el.dispatchEvent(mouse("click"));
        e.preventDefault();
    }
);

export const KEYBOARD_MODE_KEY = "__keyboardMode";
for (const [k, v] of keymap) {
    Mousetrap.bind(k, e => {
        (window as any)[KEYBOARD_MODE_KEY] = true;
        v.action(e);
    });
}

function mouse(type: "click" | "dblclick"): MouseEvent {
    const mouseEvent = new MouseEvent(type, {
        bubbles: true,
        cancelable: true,
        view: window,
    });

    (mouseEvent as any)[KEYBOARD_MODE_KEY] = true;
    return mouseEvent;
}

document.onclick = e => {
    if (KEYBOARD_MODE_KEY in e) return;
    const selected = document.querySelector<HTMLElement>("#keyboard-selected");
    if (selected) {
        selected.id = "";
    }
    delete (window as any)[KEYBOARD_MODE_KEY];
};

const xp = (xpath: string): Promise<HTMLElement[]> => new Promise(resolve => {
    let
        res = null,
        el = null,
        count = 0;

    const interval = setInterval(() => {
        res = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);
        el = res.iterateNext();
        if (el !== null && el instanceof HTMLElement) {
            clearInterval(interval);
            const arr = [el];
            while (el = res.iterateNext() && el instanceof HTMLElement) {
                arr.push(el);
            }
            resolve(arr);
        } else {
            if (++count === MAX_TRIES) {
                clearInterval(interval);
                resolve([]);
            }
        }
    }, WAIT_FOR_ASYNC_ACTION_MS);
});

async function focusByPlaceholder(placeholder: string): Promise<HTMLElement | undefined> {
    return xp(`//input[@placeholder="${placeholder}"]`)
        .then(els => {
            els[0]?.focus();
            return els[0];
        });
}

// modified from https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
function isElementInViewport (el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

function scrollSelectedIntoView(): void {
    const el = document.querySelector<HTMLElement>("#keyboard-selected");
    if (!el || isElementInViewport(el)) return;

    el.scrollIntoView({
        behavior: "smooth",
        block: "center",
    });

    el.focus();
}



function selectChild(e: Event, delta: number) {
    const children = Array.from(document.querySelectorAll<HTMLElement>(".child-body"));
    if (children.length === 0) return;

    const selectedIdx = children.findIndex(child => child.id === "keyboard-selected");
    if (selectedIdx === -1) {
        (children[delta > 0 ? 0 : children.length - 1] as HTMLElement).id = "keyboard-selected";
    } else {
        const candidate = children[selectedIdx + delta];
        if (candidate) {
            (children[selectedIdx] as HTMLElement).id = "";
            candidate.id = "keyboard-selected";
        }
    }

    scrollSelectedIntoView();
    e.preventDefault();
}

function move(e: Event, action: string) {
    const button = document.querySelector<HTMLElement>(`#keyboard-selected button.${action}`);
    if (!button) return;

    const id = button.dataset["nodeId"] as Child<Id>;

    button.dispatchEvent(mouse("click"));
    setTimeout(() => {
        const child = document.querySelector<HTMLElement>(`.child-body[data-node-id="${id}"]`);
        if (child) {
            child.id = "keyboard-selected";
            scrollSelectedIntoView();
        }
    }, WAIT_FOR_ASYNC_ACTION_MS);
    e.preventDefault();
}