import {doNothing, errorToString} from "../common/util";
import {Store} from "./store";

const KEY = 'uncaught';
const MAX = 10;

function get(): string[] {
    return Store.getItem(KEY);
}

function send() {
    if (!window.navigator.onLine) {
        return;
    }

    const err = get()[0];
    if (err === undefined) {
        return;
    }

    if (globalThis.appEnv.VERSION === "dev") {
        return;
    }

    fetch(`/errs/${globalThis.appEnv.VERSION}`, {method: "POST", body: err})
        .then(res => {
            if (res.status >= 200 && res.status < 300) {
                const storedErrs = new Set(get());
                storedErrs.delete(err);
                Store.setItem(KEY, Array.from(storedErrs));
            }
        })
        .catch(doNothing);
}

export function recordUncaughtException(s: string) {
    console.error(s);

    const errs = get();
    if (errs.length >= MAX) {
        send();
        return;
    }
    Store.setItem(KEY, Array.from(new Set(errs).add(s)));
    send();
}

window.addEventListener('error', ev => {
    recordUncaughtException(`Uncaught error: ${errorToString(ev.error)}`)
});

window.addEventListener('unhandledrejection', ev => {
    const reason = ev?.reason;
    if (typeof reason === "string") {
        recordUncaughtException(`Unhandled rejection: ${errorToString(reason)}`);
    }
});

window.addEventListener("online", send);

send();
setInterval(send, 10_000);
