import {BootstrapWindowSize, type ItemGraph} from "../../../types";
import {type GraphPath, toUrlRepr} from "../../../../common/urlpath";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup,
    ListGroup
} from "react-bootstrap";
import React, {type ReactElement, useState} from "react";
import {domainTx, handleCommands} from "../../../ui-state";
import {type Child, type Id, type Lookup, type Parent, ROOT} from "../../../../common/domain/data";
import {writeFragment} from "../../../fragment";
import {reorderChild, updateDoneState, updateTitle, updateTitleOnEnter} from "./util";
import {autoFocus, toIds, toLookups} from "../../util";
import {mapNull} from "../../../../common/util";
import {mutable} from "../../../mutable";
import {ConfirmationRequiredButton} from "../../../components/ConfirmationRequiredButton";
import {findPhoneNumber, findUrl} from "./scraping";
import InputGroupText from "react-bootstrap/InputGroupText";
import {ItemParentBadges} from "./widgets";

type GraphChildrenProps = { focus: ItemGraph, graphPath: GraphPath, windowSize: BootstrapWindowSize };

export function GraphChildren({focus, graphPath, windowSize}: GraphChildrenProps): ReactElement {
    const fullFatChildren = onlyFullFat(focus.children)
    return <ListGroup key={`children-${focus.id}`}>
        {fullFatChildren.length === 0
            ? <center><span className={"children-status"}>(no children)</span></center>
            : <ChildLayer parent={focus} graphPath={graphPath} windowSize={windowSize} children={fullFatChildren}/>
        }
    </ListGroup>;
}

function ChildLayer({parent, graphPath, windowSize, children}: Omit<GraphChildrenProps, "focus"> & {
    parent: ItemGraph,
    children: readonly ItemGraph<Id>[]
}): ReactElement {
    const notDoneChildren = children.filter(c => c.todoData?.doneState !== "done");
    const doneChildren = children.filter(c => c.todoData?.doneState === "done");

    const firstNotDoneChild = notDoneChildren[0];
    const lastNotDoneChild = notDoneChildren[notDoneChildren.length - 1];
    const firstDoneChild = doneChildren[0];
    const lastDoneChild = doneChildren[doneChildren.length - 1];

    const sortedChildren = [...notDoneChildren, ...doneChildren];

    return <>{sortedChildren.map((currSortedChild, i) => {
        const prevSortedChildModel = sortedChildren[i - 1];
        const nextSortedChildModel = sortedChildren[i + 1];

        let first, prev, next, last = undefined;
        if (currSortedChild.todoData?.doneState !== "done") {
            if (currSortedChild !== firstNotDoneChild) {
                first = firstNotDoneChild;
                prev = prevSortedChildModel;
            }
            if (currSortedChild !== lastNotDoneChild) {
                next = nextSortedChildModel;
                last = lastNotDoneChild;
            }
        } else {
            if (currSortedChild !== firstDoneChild) {
                first = firstDoneChild;
                prev = prevSortedChildModel;
            }
            if (currSortedChild !== lastDoneChild) {
                next = nextSortedChildModel;
                last = lastDoneChild;
            }
        }

        return <GraphChild
            key={currSortedChild.id}
            parent={parent}
            graphPath={graphPath}
            children={children}
            windowSize={windowSize}
            first={first}
            prev={prev}
            child={currSortedChild}
            next={next}
            last={last}
        />;
    })}</>;
}

type GraphChildProps = {
    parent: ItemGraph,
    graphPath: GraphPath,
    children: readonly ItemGraph[],
    windowSize: BootstrapWindowSize,
    first: ItemGraph<Id> | undefined,
    prev: ItemGraph<Id> | undefined,
    child: ItemGraph<Id>,
    next: ItemGraph<Id> | undefined,
    last: ItemGraph<Id> | undefined
};

function GraphChild({
                        parent,
                        graphPath,
                        children,
                        windowSize,
                        first,
                        prev,
                        child,
                        next,
                        last
                    }: GraphChildProps): ReactElement {
    const subgraphTodoCount = child.aggregatedSubGraph?.todoCount ?? 0;
    const key = [child.id, child.noteData.title, child.todoData?.doneState, subgraphTodoCount].join("-");

    return <div key={key} className={`child`} data-node-id={child.id}>
        <InputGroup data-node-id={child.id}
                    className={`child-body ${child.todoData?.doneState === "done" ? "done" : ""}`}>
            {(() => {
                const todoData = child.todoData;
                if (todoData === undefined) {
                    return <InputGroup.Text key={"note-no-checkbox"} className={"note"}>&nbsp;</InputGroup.Text>;
                }

                return (
                    <InputGroup.Checkbox
                        key={"todo-checkbox"}
                        aria-label={"checkbox for item b"}
                        type={"checkbox"}
                        checked={todoData.doneState === "done"}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                            void domainTx(tx => updateDoneState(tx, ev, child.id, todoData));
                        }}
                    />
                );
            })()}

            {(() => {
                return <InputGroup.Text className={"aggregated-size justify-content-end"}
                                        style={{fontSize: ".6em", width: "4.5em"}}>{(() => {
                    if (subgraphTodoCount === 0) return <></>;
                    return <>{subgraphTodoCount > 999 ? 999 : subgraphTodoCount}</>;
                })()}</InputGroup.Text>;
            })()}

            <DropdownButton key={"child-dropdown"} as={ButtonGroup} title="" variant={"outline-secondary"}>
                <Dropdown.Header>
                    <ButtonToolbar key={"toolbar"}>
                        <ButtonGroup key={"reorder"} className={"mr-2"}>
                            <Button
                                key={"move-top"}
                                className={"action-move-top"}
                                data-node-id={child.id}
                                variant={"outline-primary"}
                                {...(first === undefined
                                        ? {disabled: true}
                                        : {
                                            onClick: ev => {
                                                void domainTx(tx => reorderChild(tx, parent.id as Parent<Id>, ev, child.id, "above", first.id));
                                            }
                                        }
                                )}
                            >⤒</Button>
                            <Button
                                key={"move-up"}
                                className={"action-move-up"}
                                data-node-id={child.id}
                                variant={"outline-primary"}
                                {...(prev === undefined
                                        ? {disabled: true}
                                        : {
                                            onClick: ev => {
                                                void domainTx(tx => reorderChild(tx, parent.id as Parent<Id>, ev, child.id, "above", prev.id));
                                            }
                                        }
                                )}
                            >↑</Button>
                            <Button
                                key={"move-down"}
                                className={"action-move-down"}
                                data-node-id={child.id}
                                variant={"outline-primary"}
                                {...(next === undefined
                                        ? {disabled: true}
                                        : {
                                            onClick: ev => {
                                                void domainTx(tx => reorderChild(tx, parent.id as Parent<Id>, ev, child.id, "below", next.id));
                                            }
                                        }
                                )}
                            >↓</Button>
                            <Button
                                key={"move-bottom"}
                                className={"action-move-bottom"}
                                data-node-id={child.id}
                                variant={"outline-primary"}
                                {...(last === undefined
                                        ? {disabled: true}
                                        : {
                                            onClick: ev => {
                                                void domainTx(tx => reorderChild(tx, parent.id as Parent<Id>, ev, child.id, "below", last.id));
                                            }
                                        }
                                )}
                            >⤓</Button>
                        </ButtonGroup>
                        <ButtonGroup key={"connect"} className={"mr-2"}>
                            <Button
                                key={"copy"}
                                className={"action-copy"}
                                variant={"outline-primary"}
                                onClick={() => mutable.send({
                                    type: "SetGraphModal",
                                    modal: {
                                        type: "CopyChildModal",
                                        sourceId: child.id,
                                        sourceTitle: child.noteData.title,
                                        targetTitle: child.noteData.title,
                                        targetParent: {
                                            lookup: parent.id,
                                            title: parent.noteData.title,
                                            childTitles: children.map(c => c.noteData.title),
                                        },
                                        sourceParents: child.parents.map(p => mapNull(p, p => p) as Lookup<Id>),
                                        idBlockList: [
                                            child.id,
                                            ...toLookups(child.children),
                                        ],
                                        working: false,
                                    }
                                })}
                            ><i className="fal fa-copy"></i></Button>
                            <Button
                                key={"move"}
                                className={"action-move"}
                                variant={"outline-primary"}
                                onClick={() => mutable.send({
                                    type: "SetGraphModal",
                                    modal: {
                                        type: "MoveChildModal",
                                        id: child.id,
                                        idBlockList: [
                                            child.id,
                                            ...toLookups(child.parents),
                                            ...toLookups(child.children),
                                        ],
                                    }
                                })}
                            >⤞</Button>
                            <Button
                                key={"tag"}
                                className={"action-tag"}
                                variant={"outline-primary"}
                                onClick={() => mutable.send({
                                    type: "SetGraphModal",
                                    modal: {
                                        type: "TagChildModal",
                                        id: child.id,
                                        idBlockList: [
                                            child.id,
                                            ...toLookups(child.parents),
                                            ...toLookups(child.children),
                                        ],
                                    }
                                })}
                            ><i className="bi bi-tag"/></Button>
                        </ButtonGroup>
                        <ButtonGroup key={"delete"} className={"ml-3"}>
                            <DeleteButton parent={parent} child={child}/>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Dropdown.Header>
            </DropdownButton>

            {(() => {
                const url = findUrl(child.noteData.title);
                if (url === undefined) {
                    return undefined;
                }

                return <Button variant={"outline-primary"} as={"a"} href={url} target={"_blank"}>
                    <i className="bi bi-link-45deg"/>
                </Button>;
            })()}

            {(() => {
                const phoneNumber = findPhoneNumber(child.noteData.title);
                if (phoneNumber === undefined) {
                    return undefined;
                }

                return <Button variant={"outline-primary"} as={"a"} href={`tel:${phoneNumber}`}>
                    <i className="bi bi-telephone"/>
                </Button>;
            })()}

            {(() => {
                if (!child.noteData.detail) return;

                const childUrlPath = toUrlRepr({
                    type: "Graph",
                    breadcrumbs: [...graphPath.breadcrumbs, child.id],
                });

                return <InputGroupText as={"a"} href={`#${childUrlPath}`}
                                       title={"This item contains more detailed text"}>
                    <i className="bi bi-journal-text"/>
                </InputGroupText>;
            })()}

            <EditableChild child={child} breadcrumbs={graphPath.breadcrumbs} windowSize={windowSize}/>
        </InputGroup>
        <ItemParentBadges parents={toIds(child.parents)} currParent={parent.id}/>
        {(() => {
            const fullFatChildren = onlyFullFat(child.children);
            if (fullFatChildren.length === 0) return <></>;

            return <div style={{marginLeft: "2em", marginTop: "0.5em"}}>
                <ChildLayer parent={child} graphPath={graphPath} windowSize={windowSize} children={fullFatChildren}/>
            </div>;
        })()}
    </div>;
}

type EditableChildProps = {
    child: ItemGraph<Id>,
    breadcrumbs: readonly Id[],
    windowSize: BootstrapWindowSize,
};

function EditableChild({child, breadcrumbs, windowSize}: EditableChildProps): ReactElement {
    const [editing, setEditing] = useState(false);
    const [title, setTitle] = useState(child.noteData.title as string);

    if (editing) {
        return <FormControl
            key={"editing"}
            data-node-id={child.id}
            className={`child-title ${child.children.length > 0 ? "has-children" : ""}`}
            aria-label={`Summary for child ${title}`}
            autoFocus={autoFocus(windowSize)}
            value={title}
            onChange={ev => setTitle(ev.target.value)}
            onKeyDown={ev => {
                void domainTx(tx => updateTitleOnEnter(tx, ev, title, child));
            }}
            onBlur={() => {
                void domainTx(tx => updateTitle(tx, title, child));
                setEditing(false);
            }}
        />;
    } else {
        const childUrlPath = toUrlRepr({
            type: "Graph",
            breadcrumbs: [...breadcrumbs, child.id]
        });
        return <InputGroup.Text
            as={"a"}
            href={"#" + childUrlPath}
            data-node-id={child.id}
            key={"viewing"}
            className={`child-title text-truncate viewing form-control ${child.children.length > 0 ? "has-children" : ""}`}
            aria-label={`Summary for child ${title}`}
            onClick={(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                ev.preventDefault();
                if (mutable.doubleClickHack === null) {
                    mutable.doubleClickHack = setTimeout(() => {
                        mutable.doubleClickHack = null;
                        writeFragment(`#${childUrlPath}`);
                    }, 250);
                }
            }}
            onDoubleClick={(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                ev.preventDefault();
                if (mutable.doubleClickHack !== null) {
                    clearTimeout(mutable.doubleClickHack);
                    mutable.doubleClickHack = null;
                }
                setEditing(true);
            }}
        >{title.match(/^[_*-]{3,}$/) ? <hr className={"w-100"}/> : title}</InputGroup.Text>;
    }
}

function onlyFullFat(items: readonly (Lookup<Id> | ItemGraph<any>)[]): readonly ItemGraph<Id>[] {
    return items.flatMap(item => {
        if (item === ROOT || typeof item === "string") return []
        return [item];
    });
}

type RemoveChoice = "delete" | "removeLayer";

interface FooProps {
    parent: ItemGraph,
    child: ItemGraph<Id>
}

function DeleteButton({parent, child}: FooProps): ReactElement {
    const [removeChoice, setRemoveChoice] = useState<RemoveChoice>("delete");

    const childHasOtherParents = child.parents.filter(p => p !== parent.id).length > 0;
    const nodesInSubGraph = child.aggregatedSubGraph?.nodeCount ?? 1;
    return <ConfirmationRequiredButton
        key={"delete"}
        className={"action-delete"}
        variant={"outline-danger"}
        detail={child.children.length === 0 ? "" : <Form>
            <Form.Check
                type={"radio"}
                id={"delete"}
                label={childHasOtherParents ? "Detach" : `Delete ${nodesInSubGraph + 1} item${nodesInSubGraph > 1 ? "s" : ""}`}
                checked={removeChoice === "delete"}
                onChange={() => setRemoveChoice("delete")}
            />
            <Form.Check
                type={"radio"}
                id={"removeLayer"}
                label={`Remove layer, attach ${child.children.length} item${child.children.length > 1 ? "s" : ""} to ${parent.noteData.title}`}
                checked={removeChoice === "removeLayer"}
                onChange={() => setRemoveChoice("removeLayer")}
            />
        </Form>}
        onConfirm={ev => {
            ev.preventDefault();
            void domainTx(tx => handleCommands(tx, [{
                type: removeChoice === "delete"
                    ? "DetachChild"
                    : "RemoveLayer",
                parent: parent.id as Parent<Id>,
                child: child.id as Child<Id>,
            }]));
        }}
    >×</ConfirmationRequiredButton>
}