import * as React from 'react';
import {errorToString} from "../../common/util";
import {recordUncaughtException} from "../error-reporting";
import {type ReactElement} from "react";
import {createRoot} from "react-dom/client";

export type Send<Msg> = (msg: Msg) => void;

export function mollari<Model, Msg extends {type: string}>(
    hostElementId: string,
    initialModel: Model,
    modelEq: (m1: Model, m2: Model) => boolean,
    view: (model: Model, send: Send<Msg>) => React.ReactElement,
    update: (model: Model, msg: Msg) => Model,
): [Send<Msg>, () => Model] {
  const hostElement = document.getElementById(hostElementId);
  if (hostElement === null) {
      throw Error(`Can't find element with id '${hostElementId}'`);
  }

  const root = createRoot(hostElement!);

  let model = initialModel;
  function render(): ReactElement {
      try {
          return view(model, send)
      } catch (e) {
          const err = errorToString(e);
          recordUncaughtException(`Mollari doesn't have time for this: ${err}`);
          return <pre>{err}</pre>;
      }
  }

  function send(msg: Msg) {
    const updatedModel = update(model, msg);
    if (!modelEq(model, updatedModel)) {
      model = updatedModel;
      root.render(render());
    }
  }

  root.render(render());

  return [send, () => model];
}