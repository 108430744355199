import {
    type AccountConfiguration,
    type Entrypoint,
    type Id,
    type Lookup,
    type NamedGeoLocation,
    ROOT,
    type Scheduled,
    type ScheduledId,
    type ScheduleMutable
} from "../common/domain/data";
import {type DomainState, removeSubGraph} from "../common/domain/logic";
import {type IxItemThin} from "./types";
import {getItem} from "./get-item";
import {isSomething, throwIfNotSomething} from "../common/util";
import {FancyTransaction} from "./db";
import {lru} from "./lru";
import {type ISO8601_DateTime} from "../common/datetime";
import {mutable} from "./mutable";

export class IndexeddbDomainState implements DomainState<FancyTransaction> {
    async writeRoot(tx: FancyTransaction, root: Entrypoint<Id>): Promise<void> {
        await tx.table("entrypoints").put({id: "root", val: root});

        const oldSearchRoot = mutable.search.get(ROOT)
        const newSearchRoot = {...oldSearchRoot};
        newSearchRoot.children = root.children;
        newSearchRoot.newChildLocation = root.newChildLocation;
        mutable.search.remove(oldSearchRoot);
        mutable.search.add(newSearchRoot);

        tx.searchIndexHasPendingChanges();
    }

    async getRoot(tx: FancyTransaction): Promise<Entrypoint<Id>> {
        const root = await tx.table("entrypoints").get("root");
        if (!root) {
            throw new Error("No root exists");
        }

        return root.val;
    }

    async writeItem(tx: FancyTransaction, item: IxItemThin): Promise<void> {
        if (item.parents.length === 0) {
            throw new Error(`Don't use writeItem to detach items - use removeItems (id = ${item.id}, deleted = ${item.deleted})`);
        }

        const oldItem = await getItem(tx, item.id);

        await tx.table("items").put(item);
        lru.set(item.id, item);

        if (oldItem) {
            try {
                mutable.search.remove(oldItem);
                tx.searchIndexHasPendingChanges();
            } catch (e) {
                // ignored
            }
        }

        if (item.parents.length > 0) {
            mutable.search.add(item);
            tx.searchIndexHasPendingChanges();
        }
    }

    async removeItems(tx: FancyTransaction, time: ISO8601_DateTime, ...removedEntrypoints: Id[]): Promise<{[key: Id]: IxItemThin}> {
        const entrypointsTbl = tx.table('entrypoints');
        const itemsTbl = tx.table('items');

        const removed = await removeSubGraph(
            async () => throwIfNotSomething("Expected root", await entrypointsTbl.get("root")).val,
            async ids => (await itemsTbl.bulkGet(ids)).filter(isSomething),
            ...removedEntrypoints
        );

        await itemsTbl.bulkPut(Object.values(removed).map(item => ({...item, deleted: time})));

        return removed;
    }

    async getItem(tx: FancyTransaction, id: Id): Promise<IxItemThin | undefined> {
        const cachedItem = lru.get(id);
        if (cachedItem) {
            return cachedItem;
        }

        const item = await tx.table("items").get(id);
        if (item) {
            lru.set(id, item);
        }

        return item;
    }

    async getItemThrows(tx: FancyTransaction, name: string, id: Id): Promise<IxItemThin> {
        const item = await this.getItem(tx, id);
        if (!item) {
            throw new Error(`${name} '${id}' does not exist`);
        }

        return item;
    }

    async getAllItems(_tx: FancyTransaction): Promise<IxItemThin[]> {
        throw new Error("Not needed");
    }

    async setConfig<K extends keyof AccountConfiguration>(tx: FancyTransaction, key: K, value: null | AccountConfiguration[K]): Promise<void> {
        if (value === null) {
            await tx.table("config").delete(key);
        } else {
            await tx.table("config").put({key, value});
        }
    }
    async getConfig<K extends keyof AccountConfiguration>(tx: FancyTransaction, key: K): Promise<undefined | AccountConfiguration[K]> {
        const result = await tx.table("config").get(key);
        if (result === undefined) {
            return undefined;
        }

        return result.value;
    }

    async getAllConfig(tx: FancyTransaction): Promise<AccountConfiguration> {
        const config: AccountConfiguration = {};
        await tx.table("config").each(o => {
            config[o.key] = o.value;
        });

        return config;
    }

    async addGeoLocation(tx: FancyTransaction, geoLocation: NamedGeoLocation): Promise<void> {
        await tx.table("geoLocations").put(geoLocation);
        return Promise.resolve(undefined);
    }

    async getGeoLocations(tx: FancyTransaction): Promise<Readonly<{ [key: string]: NamedGeoLocation }>> {
        const geoLocations: { [key: string]: NamedGeoLocation } = {};
        (await tx.table("geoLocations").toArray()).forEach(l => { geoLocations[l.name] = l; });
        return geoLocations;
    }

    async removeGeoLocation(tx: FancyTransaction, name: string): Promise<void> {
        await tx.table("geoLocations").delete(name);
    }

    async setScheduled(tx: FancyTransaction, scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>): Promise<void> {
        await tx.table("schedule").put(scheduled);
        return Promise.resolve(undefined);
    }

    async getSchedule(tx: FancyTransaction): Promise<Readonly<{[key: string]: Scheduled<ScheduledId, readonly Lookup<Id>[]>}>> {
        const schedule: ScheduleMutable = {};
        (await tx.table("schedule").toArray()).forEach(s => { schedule[s.id] = s; });
        return schedule;
    }

    async removeScheduled(tx: FancyTransaction, id: ScheduledId): Promise<void> {
        await tx.table("schedule").delete(id);
    }

    async abort(tx: FancyTransaction): Promise<void> {
        await tx.abort();
    }
}
