import {type SettingsPath, toUrlRepr} from "../../../common/urlpath";
import React, {type ReactElement, useState} from "react";
import {Button, Card, Form, ListGroup, ListGroupItem} from "react-bootstrap";
import {type Model} from "../../types";
import {RestoreFromBackup} from "../util";
import {Store} from "../../store";
import {deleteDomainData} from "../../db";
import {
    PARAM_EXCHANGE_EMAIL_TOKEN_FOR_SESSION_TOKEN,
    PATH_EXCHANGE_EMAIL_TOKEN_FOR_SESSION_TOKEN
} from "../../../common/config";
import {hexAndDashesOnly} from "../../../common/util";
import {writeFragment} from "../../fragment";
import {CardSpinner} from "../../components/CardSpinner";

export function settingsView(model: Model, path: SettingsPath): ReactElement {
    const settingsComponent = path.component;
    switch (settingsComponent) {
        case undefined:
            return (
                <ListGroup key={"settings"} className={"link-list"}>
                    <ListGroupItem>
                        <RestoreFromBackup/>
                    </ListGroupItem>
                </ListGroup>
            );

        case "login":
            return <Login />;

        case "token-submission":
            return <TokenSubmitForm />;

        case "bad-session":
            return (
                <Card key={"bad-session"}>
                    <Card.Header key={"header"}>Bad session</Card.Header>
                    <Card.Body key={"body"}>
                        <Button key={"reset"} variant={"danger"} onClick={ev => {
                            ev.preventDefault();
                            Store.removeItem("session");
                            void deleteDomainData();
                        }}>
                            Reset
                        </Button>
                    </Card.Body>
                    <Card.Footer key={"footer"}>
                        This will remove all local data, including your session, you will
                        need to login to reload your data
                    </Card.Footer>
                </Card>
            );

        default:
            const _: never = settingsComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(settingsComponent)}`);
    }
}

type LoginResult
    = {type: "none"}
    | {type: "error", message: string}
    | {type: "contacting-server"};

function Login(): ReactElement {
    const [email, setEmail] = useState("");
    const [result, setResult] = useState<LoginResult>({type: "none"});

    if (result.type === "contacting-server") {
        return <CardSpinner text={"sending email"} />;
    }

    return <Card key={"acct-reg"}>
        <Card.Header key={"header"}>
            <Card.Title>Account login</Card.Title>
        </Card.Header>
        <Card.Body key={"body"}>
            {(() => {
                switch (result.type) {
                    case "none":
                        return <Form onSubmit={ev => {
                            ev.preventDefault();
                            if (!(ev.target as HTMLFormElement).checkValidity()) return;
                            setResult({type: "contacting-server"});

                            void fetch(`/api/requestSessionByEmail`, {method: "POST", body: email})
                                .then(res => {
                                    if (res.status === 202) {
                                        writeFragment(`#${toUrlRepr({type: "Settings", component: "token-submission"})}`);
                                    } else {
                                        res.text().then(err => {
                                            setResult({type: "error", message: err});
                                        });
                                    }
                                });
                        }}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" autoFocus={true} value={email} onChange={ev => setEmail(ev.target.value)}/>
                            </Form.Group>
                            <Button key={"submit"} variant={"primary"} type={"submit"}>
                                Submit
                            </Button>
                        </Form>;

                    case "error":
                        return result.message;

                    default: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const _: never = result;
                        // noinspection ExceptionCaughtLocallyJS
                        throw new Error(`Unexpected object: ${JSON.stringify(result)}`);
                    }
                }
            })()}
        </Card.Body>
    </Card>;
}

function TokenSubmitForm(): ReactElement {
    const [code, setCode] = useState("");

    const validation = hexAndDashesOnly;

    return <Card>
        <Card.Header key={"header"}>
            <Card.Title>Submit the token sent to your email</Card.Title>
        </Card.Header>
        <Card.Body key={"body"}>
            <p>Check your email for a magic token to paste in here, or click the direct login link in that email.</p>

            <Form action={PATH_EXCHANGE_EMAIL_TOKEN_FOR_SESSION_TOKEN}>
                <Form.Group className="mb-3" key={"code"}>
                    <Form.Label>Code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter code from email"
                        autoFocus={true}
                        name={PARAM_EXCHANGE_EMAIL_TOKEN_FOR_SESSION_TOKEN}
                        value={code}
                        onChange={ev => setCode(validation(ev.target.value))}
                    />
                </Form.Group>
                <Button
                    key={"submit"}
                    variant={"primary"}
                    type={"submit"}
                >
                    Submit
                </Button>
            </Form>
        </Card.Body>
    </Card>;
}