export function findUrl(s: string): string | undefined {
    const m = s.match(/(https?:\/\/|www\.)[^ ()\[\]<>{}]+|[^ "'()\[\]<>{}]+[a-z](\.([a-z]+))+[^ "'()\[\]<>{}]+/);
    return m !== null
        ? (!m[0].match(/^https?:\/\//)
            ? 'http://' + m[0]
            : m[0])
        : undefined;
}

export function findPhoneNumber(s: string): string | undefined {
    const m = s.match(/(^|[^a-zA-Z0-9 /=+-])(\+?[0-9][0-9 -]{4,}[0-9])\b/);
    return m !== null
        ? m[2]
        : undefined;
}