import {
    type Id,
    type IndexedItem,
    type Lookup,
    type NamedGeoLocation,
    type Parent,
    type Schedule,
    type ScheduledId,
    type Title,
} from "../common/domain/data";
import {type UrlPath} from "../common/urlpath";
import {type UiEvent} from "../common/domain/logic";
import {defaultLastScheduleExecution} from "../common/domain/scheduling";
import {type SessionId} from "../common/protocol";
import {loadItemGraph} from "./ui-state";

export type NotificationLevel = "info" | "error";
export type Notification = {
    level: NotificationLevel,
    title: string,
    message: string,
    timeoutSeconds?: number,
};

export type Model = Readonly<{
    connectionStatus: ConnectionStatus,
    commandGroupCount: number,
    notifications: Notification[], // TODO: move to local state
    windowSize: BootstrapWindowSize,
    path: UrlPath,
    session: SessionModel,
    graph: Readonly<GraphModel>,
    geoLocation: null | {
        fallbackPath: null | string,
        locations: Readonly<{
            editing: null | NamedGeoLocation, // TODO: move to local state
            named: Readonly<{ [key: string]: NamedGeoLocation }>
        }>,
    },
    scheduling: {
        lastScheduleExecution: Date,
        editing: null | ScheduledId, // TODO: move to local state
        schedule: Schedule,
    },
}>;

export function initialModel(commandGroupCount: number, windowSize: BootstrapWindowSize, path: UrlPath, session: SessionModel): Model {
    return {
        notifications: [],
        connectionStatus: "not-yet-connected",
        commandGroupCount,
        windowSize,
        path,
        session,
        graph: {
            breadcrumbs: [],
            focus: loadItemGraph(path, 1),
            childDepth: 1,
            modal: null,
        },
        geoLocation: null,
        scheduling: {
            lastScheduleExecution: defaultLastScheduleExecution(),
            editing: null,
            schedule: {},
        },
    };
}

export type Msg
    = { type: "Notify", notifications: Notification[] }
    | { type: "DismissNotification", notification: Notification }
    | { type: "Disconnected" }
    | { type: "StateLoaded" }
    | { type: "CommandGroupCount", count: number }
    | { type: "PathChange", path: UrlPath }
    | { type: "ProtocolLoggedIn", session: SessionId }
    | { type: "ProtocolBadSession" }
    | { type: "Events", source: Source, events: readonly ExpandedEvent[] }
    | { type: "SetGraphModal", modal: null | GraphModal }
    | { type: "WindowSizeChanged", size: BootstrapWindowSize }
    | { type: "LoadGeo", fallbackPath: string | null, locations: Readonly<{[key: string]: NamedGeoLocation}> }
    | { type: "EditNamedLocation", location: NamedGeoLocation | null }
    | { type: "LoadSchedule", lastScheduleExecution: Date, schedule: Schedule }
    | { type: "EditScheduled", id: ScheduledId | null }
    | { type: "SetChildDepth", depth: number }
    ;

export enum BootstrapWindowSize { ExtraSmall, Small, Medium, Large, ExtraLarge }
export type ConnectionStatus = "not-yet-connected" | "connected" | "disconnected-retrying";
export type GeoStatus = { type: "not-yet-set" } | { type: "error", message: string } | { type: "searching" } | { type: "position", pos: GeolocationPosition };

export type SessionModel
    = { type: "NoSessionFound" }
    | { type: "NotYetLoggedIn", session: SessionId }
    | { type: "LoggedIn", session: SessionId }
    | { type: "BadSessionError" }
    ;

export type IxItemThin = IndexedItem<Id, Id>;
export type IxItemGrandparent = IxItemThin;
export type IxItemParent = { type: "IxItemParent" } & IndexedItem<IxItemGrandparent, Id>;
export type IxItemChild = { type: "IxItemChild" } & IndexedItem<IxItemThin, Id>;
export type ExpandedEvent = UiEvent<IxItemParent, IxItemChild>;
export const ROOT_THIN = null as Lookup<IxItemThin> as Parent<IxItemThin>;
export const ROOT_PARENT = null as Lookup<IxItemParent> as Parent<IxItemParent>;

export interface ItemGraph<Identifier = Lookup<Id>> extends IndexedItem<
    Id | ItemGraph<Lookup<Id>>,
    Id | ItemGraph<Id>,
    Identifier
> {} // NB. can't be a type alias because of circular reference

export type GraphModel = {
    breadcrumbs: readonly ItemGraph[],
    focus: ItemGraph,
    childDepth: number,
    modal: null | GraphModal, // TODO: move to local state
};

export type GraphModal
    = { type: "CopyChildModal", sourceId: Id, sourceTitle: Title, targetTitle: Title, sourceParents: readonly Lookup<Id>[], idBlockList: readonly Lookup<Id>[], targetParent: { lookup: Lookup<Id>, title: Title, childTitles: Title[] }, working: boolean }
    | { type: "MoveChildModal", id: Id, idBlockList: readonly Lookup<Id>[] }
    | { type: "TagChildModal", id: Id, idBlockList: readonly Lookup<Id>[] }
    ;

export type Source = "SrcLocal" | "SrcRemote";