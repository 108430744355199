const TE = new TextEncoder();
const TD = new TextDecoder();

export function utf8(x: Uint8Array): string;
export function utf8(x: string): Uint8Array;
export function utf8(x: string | Uint8Array): string | Uint8Array {
    return typeof x === "string"
        ? TE.encode(x)
        : TD.decode(x);
}

// Custom type predicate util
export function isSomething<T>(x: T | undefined): x is T {
    return x !== undefined;
}
export function isNotNull<T>(x: T | null): x is T {
    return x !== null;
}

// Custom type predicate util
export function throwIfNotSomething<T>(msg: string, x: T | undefined): T {
    if (x === undefined) {
        throw new Error(msg);
    }

    return x;
}

export function newPromise<T>(): [Promise<T>, (value: (T | PromiseLike<T>)) => void, (reason?: any) => void] {
    let resolve: (value: (T | PromiseLike<T>)) => void;
    let reject: (reason?: any) => void;

    const promise = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [promise, resolve, reject];
}

export function mapUndef<In, Out>(x: In | undefined, f: (x2: In) => Out): Out | undefined {
    return maybeUndef(x, undefined, f);
}

export function maybeUndef<In, Out>(x: In | undefined, def: Out, f: (x2: In) => Out): Out {
    return x === undefined
        ? def
        : f(x);
}

export function mapNull<In, Out>(x: In | null, f: (x2: In) => Out): Out | null {
    return maybeNull(x, null, f);
}

export function maybeNull<In, Out>(x: In | null, def: Out, f: (x2: In) => Out): Out {
    return x === null
        ? def
        : f(x);
}

export function errorToString(err: any): string {
    if (typeof err === "string") {
        return err;
    }

    const name = err.name;
    const message = err.message;
    const stack = err.stack;

    if (typeof stack === "string" && stack.startsWith(name)) {
        return stack;
    }

    if (message) {
        return (name ? `${name}: ` : "") + message + (stack ? `\n${stack.replace(/^/gm, "  ")}` : "");
    }

    return JSON.stringify(err);
}

export function doNothing(): void {
    /* do nothing */
}

export type PromiseOrNot<T> = Promise<T> | T;

export function logStackTrace(msg = "logStackTrace") {
    try {
        throw new Error(msg);
    } catch (e) {
        console.error(e);
    }
}

export function xpathContainsClass(klass: string): string {
    return `contains(concat(" ", normalize-space(@class), " "), " ${klass} ")`;
}

export function hexOnly(s: string): string {
    return s.replace(/[^a-f0-9]/, "");
}

export function hexAndDashesOnly(s: string): string {
    return s.replace(/[^a-f0-9-]/, "");
}