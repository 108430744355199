import React from "react";
import {
    type Child,
    type Completable,
    type Detail,
    type DoneState,
    type Id,
    type IndexedItem,
    type Parent,
    type RelativePosition,
    type Title,
    type TodoData
} from "../../../../common/domain/data";
import {type DomainTransaction, handleCommands} from "../../../ui-state";
import {normalizeDetail, normalizeTitle} from "../../../../common/domain/logic";
import {blurEverything} from "../../../util";


export async function updateCompletable(tx: DomainTransaction, checkboxVal: string, id: Id, todoData?: TodoData): Promise<void> {
    const oldCompletable: Completable = todoData === undefined
        ? "is-not-completable"
        : "is-completable";

    const newCompletable: Completable = checkboxVal !== "true"
        ? "is-not-completable"
        : "is-completable";

    if (newCompletable === oldCompletable) {
        return;
    }

    await handleCommands(tx, [{
        type: "EditCompletable",
        item: id,
        from: oldCompletable,
        to: newCompletable,
    }]);
}


export async function updateNewChildLocation(tx: DomainTransaction, location: string, parent: Parent<Id>): Promise<void> {
    switch (location) {
        case "top":
        case "bottom":
            break;
        default:
            throw new Error(`Unknown location value ${location}`);
    }

    await handleCommands(tx, [{
        type: "UpdateNewChildLocation",
        parent,
        location
    }]);
}

export async function updateDoneState(tx: DomainTransaction, ev: React.ChangeEvent<HTMLInputElement>, id: Id, todoData: TodoData): Promise<void> {
    const oldDoneState: DoneState = todoData.doneState;

    const newDoneState: DoneState = todoData.doneState === "done"
        ? "todo"
        : "done";

    if (newDoneState === oldDoneState) {
        return;
    }

    await handleCommands(tx, [{
        type: "EditDone",
        item: id,
        from: oldDoneState,
        to: newDoneState,
    }]);
}

export async function updateTitleOnEnter(tx: DomainTransaction, ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, title: string, ixItem: IndexedItem<any, any>): Promise<void> {
    if (ev.key !== "Enter") {
        return;
    }

    ev.preventDefault();

    await updateTitle(tx, title, ixItem);
    blurEverything();
}

export async function updateTitle(tx: DomainTransaction, title: string, ixItem: IndexedItem<any, any>): Promise<void> {
    const oldTitle = ixItem.noteData.title;
    const newTitle = normalizeTitle(title as Title);

    if (newTitle === oldTitle) return;

    await handleCommands(tx, [{
        type: "EditTitle",
        item: ixItem.id,
        from: oldTitle,
        to: newTitle,
    }]);
}

export async function updateDetail(tx: DomainTransaction, detail: string, ixItem: IndexedItem<any, any, any>): Promise<void> {
    const oldDetail = ixItem.noteData.detail;
    const newDetail_ = normalizeDetail(detail as Detail);
    const newDetail = newDetail_ === ""
        ? undefined
        : newDetail_;

    if (newDetail === oldDetail) {
        return;
    }

    await handleCommands(tx, [{
        type: "EditDetail",
        item: ixItem.id,
        ...(oldDetail !== undefined && {from: oldDetail}),
        ...(newDetail !== undefined && {to: newDetail}),
    }]);
}

export async function reorderChild(tx: DomainTransaction, parent: Parent<Id>, ev: React.MouseEvent<HTMLButtonElement>, id: Id, position: RelativePosition, sibling: Id): Promise<void> {
    ev.preventDefault();
    await handleCommands(tx, [{
        type: "ReorderChild",
        parent,
        child: id as Child<Id>,
        position,
        sibling: sibling as Child<Id>,
    }]);
}
