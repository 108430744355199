import React, {type ReactElement} from "react";
import {BootstrapWindowSize, type GraphModel} from "../types";
import {type Id, type Lookup, ROOT} from "../../common/domain/data";
import {maybeUndef} from "../../common/util";
import {Button} from "react-bootstrap";
import {deleteDomainData} from "../db";
import {clientDebug} from "../client-logging";
import {KEYBOARD_MODE_KEY} from "../keyboard-shortcuts";

export function getParentForm(ev: React.SyntheticEvent<HTMLElement>): HTMLFormElement {
    const form = (ev.target as HTMLElement).closest('form');
    if (form === null) {
        throw new Error("No form found");
    }
    return form;
}

export function getFormValues(node: HTMLFormElement): { [key: string]: string } {
    const obj: { [key: string]: string } = {};
    node.querySelectorAll('input, textarea, select').forEach(el => {
        const el2 = el as HTMLInputElement;
        obj[el2.name] = el2.type === 'checkbox'
            ? el2.checked.toString()
            : el2.value;
    });

    return obj;
}

export function searchBlocklistItem(graphModel: GraphModel): Lookup<Id>[] {
    return [graphModel.focus.id as Lookup<Id>];
}

export function searchBlocklistParents(graphModel: GraphModel): readonly Lookup<Id>[] {
    return toLookups(graphModel.focus.parents);
}

export function searchBlocklistChildren(graphModel: GraphModel): readonly Lookup<Id>[] {
    return toLookups(graphModel.focus.children);
}

export function toLookup(item: Lookup<Id> | {id: Lookup<Id>}): Lookup<Id> {
    return item === ROOT || typeof item === "string" ? item : item.id as Lookup<Id>;
}

export function toLookups(items: readonly (Lookup<Id> | {id: Lookup<Id>})[]): readonly Lookup<Id>[] {
    return items.map(toLookup);
}

export function toIds(items: readonly (Lookup<Id> | {id: Lookup<Id>})[]): readonly Id[] {
    return items.flatMap(item => {
        const lookup = toLookup(item);
        return lookup === ROOT ? [] : [lookup];
    });
}

export function autoFocus(windowSize: BootstrapWindowSize): boolean {
    if (KEYBOARD_MODE_KEY in window) return false;
    return windowSize >= BootstrapWindowSize.Medium;
}

export function targetVal(ev: React.ChangeEvent<HTMLInputElement>): string {
    const t = ev.target;
    return t.type === 'checkbox'
        ? (t.checked
            ? 'true'
            : 'false')
        : t.value;
}

export function xpath(xpathExpr: string, root: HTMLElement): HTMLElement[] {
    const res = document.evaluate(xpathExpr, root, null, XPathResult.ANY_TYPE, null);

    const nodes = [];
    let node;
    while (node = res.iterateNext()) {
        if (node instanceof HTMLElement) {
            nodes.push(node);
        }
    }

    return nodes;
}

export function focusFirst(nodes: HTMLElement[]): void {
    nodes[0]?.focus();
}

export function setFormValues(node: HTMLFormElement, vals: { [key: string]: string }) {
    node.querySelectorAll('input, textarea')
        .forEach(n => {
            if (n instanceof HTMLInputElement && n.name in vals) {
                if (n.type === 'checkbox') {
                    n.checked = vals[n.name] === 'true';
                } else {
                    n.value = maybeUndef(vals[n.name], "", x => x);
                }
            }
        });
}

export function clearFormValues(node: HTMLFormElement, currentValues: {[key: string]: string}): void {
    const clearedValues: {[key: string]: string} = {};
    Object.keys(currentValues).forEach(k => clearedValues[k] = "");
    setFormValues(node, clearedValues);
}

export function restoreDomainFromServer(): void {
    void deleteDomainData()
        .then(() => {
            window.location.pathname = "/";
        });
}

export function RestoreFromBackup(): ReactElement {
    return (
        <Button key={"resync"} onClick={ev => {
            ev.preventDefault();
            restoreDomainFromServer();
        }}>
            Restore from backup
        </Button>
    );
}

export function emptyState(): ReactElement {
    debugger;
    clientDebug(() => "Rendering empty view");
    return <></>;
}
