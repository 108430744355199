import {type Id, type IndexedItem, type Parent, ROOT} from "./data";
import {type PromiseOrNot} from "../util";

export async function reachableFromRoot(
    getItems: (ids: readonly Id[]) => PromiseOrNot<IndexedItem<Id, Id>[]>,
    parentsIds: readonly Parent<Id>[],
    idsToAvoid: readonly Id[]
): Promise<boolean> {
    const allowedParents = (p: null | Parent<Id>) => p === ROOT || !idsToAvoid.includes(p);

    while (parentsIds.length > 0) {
        if (parentsIds.includes(ROOT)) {
            return true;
        }

        parentsIds = (await getItems(parentsIds as unknown as Id[] /* NB. parentIds must now be a list of non-null items, but TypeScript's narrowing doesn't work well enough to tell */))
            .filter(parentItem => {
                if (parentItem.deleted) {
                    // this case is probably due to removeSubGraph() not severing the relationship at the edge of the graph (which detach() does)
                    // throw new Error(`Traversing dead part of graph (${parentItem.id}) - the subtree might not have been deleted correctly`);
                    return false;
                }

                return true;
            })
            .flatMap(parentItem => parentItem.parents)
            .filter(allowedParents)
    }

    return false;
}