import {type Id, type Lookup} from "../../common/domain/data";
import {Badge} from "react-bootstrap";
import {
    clearComboboxState,
    Combobox,
    type ComboboxProps,
    type ComboboxState,
    type InitialComboboxState,
    type ReactState,
    useComboboxState
} from "./Combobox";
import React, {type ReactElement, useState} from "react";
import {doNothing} from "../../common/util";
import {type SearchOptions} from "minisearch";
import {type ItemGraph} from "../types";
import {toIds} from "../view/util";
import {mutable} from "../mutable";

const SEARCH_RESULTS_COUNT_LIMIT = 10;
const PREFER_NODES_WITH_CHILDREN = true;

// this list avoids end-users overwriting Combobox functionality we use in here
type UsedComboboxProps = "state"|"itemToElement"|"itemToText"|"input"|"items"|"chosenItem"|"onInputChange"|"onItemChosen"|"onEscape"|"itemStyle";
type ItemTitleProps = Omit<ComboboxProps<ItemGraph>, UsedComboboxProps | "initialState"> & {
    initialState?: InitialItemTitleState,
    state?: ItemTitleState,
    searchOptions?: SearchOptions,
    idBlockList?: readonly Lookup<Id>[],
    onItemChosen?: (item: null | ItemGraph, state: ItemTitleState) => void,
    [key: string]: any,
};

export type ItemTitleState = ComboboxState & {
    input: ReactState<string>,
    items: ReactState<readonly ItemGraph[]>,
    chosenItem: ReactState<null | ItemGraph>,
}

export type InitialItemTitleState = InitialComboboxState & {
    input?: string,
    items?: readonly ItemGraph[],
    chosenItem?: null | ItemGraph,
};

export function useItemTitleState(
    {
        input = "",
        items = [],
        chosenItem = null,
        ...initialComboboxState
    }: InitialItemTitleState = {}): ItemTitleState {
    return {
        input: useState(input),
        items: useState<readonly ItemGraph[]>(items),
        chosenItem: useState<null | ItemGraph>(chosenItem),
        ...useComboboxState(initialComboboxState),
    };
}

export function clearItemTitleState(state: ItemTitleState) {
    clearComboboxState(state);

    const [_input, setInput] = state.input;
    const [_items, setItems] = state.items;
    const [_chosenItem, setChosenItem] = state.chosenItem;

    setInput("");
    setItems([]);
    setChosenItem(null);
}

export function ItemTitle(
    {
        initialState = {},
        // eslint-disable-next-line react-hooks/rules-of-hooks
        state = useItemTitleState(initialState),
        searchOptions = {},
        onItemChosen = doNothing,
        idBlockList = [],
        ...props
    }: ItemTitleProps): ReactElement {
    const [input, setInput] = state.input;
    const [items, setItems] = state.items;
    const [chosenItem, setChosenItem] = state.chosenItem;

    searchOptions = {
        ...searchOptions,
        filter: result => idBlockList.indexOf(result.id) === -1,
    };

    return <Combobox<ItemGraph>
        {...props}
        state={state}
        itemToElement={item => <>
            {item.noteData.title}
            {toIds(item.parents).map(p => <Badge bg={"info"} key={p} style={{marginLeft: "0.2em"}}>{mutable.search.get(p)?.noteData.title}</Badge>)}
        </>}
        itemToText={item => item.noteData.title}
        input={input}
        items={items}
        chosenItem={chosenItem}
        onInputChange={input => {
            const results = mutable.search.search(input, searchOptions).slice(0, SEARCH_RESULTS_COUNT_LIMIT);

            // ordered groupings of results
            const results1: ItemGraph[] = [];
            const results2: ItemGraph[] = [];
            const results3: ItemGraph[] = [];
            results.forEach(result => {
                if (result.todoData?.doneState !== 'done') {
                    if (PREFER_NODES_WITH_CHILDREN) {
                        if (result.children.length > 0) {
                            results1.push(result);
                        } else {
                            results2.push(result);
                        }
                    } else {
                        results1.push(result);
                    }
                } else {
                    results3.push(result);
                }
            });

            setInput(input);
            setItems([...results1, ...results2, ...results3]);
        }}
        onItemChosen={item => {
            setChosenItem(item);
            if (item !== null) {
                setInput(item.noteData.title);
            }

            onItemChosen(item, state);
        }}
        onEscape={_e => {
            if (chosenItem === null) {
                setInput("");
            }
        }}
        itemStyle={item => ({
            textDecoration: item.todoData?.doneState === 'done' ? "line-through" : "none",
            fontWeight: item['children'].length > 0 ? "bold" : "normal",
        })}
    />;
}