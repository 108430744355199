import {BootstrapWindowSize, type GeoStatus, type Model} from "../../types";
import {type LocationPath, toUrlRepr} from "../../../common/urlpath";
import {mutable} from "../../mutable";
import {Button, Card, Form, InputGroup} from "react-bootstrap";
import {domainTx, handleCommands} from "../../ui-state";
import {DEFAULT_PATH_STR} from "../../util";
import React, {type ReactElement, useEffect, useState} from "react";
import {writeFragment} from "../../fragment";
import {now} from "../../../common/datetime";
import {findOverlapping, THRESHOLD_LOCATING_METRES, THRESHOLD_SAVING_METRES} from "../../../common/domain/geolocation";
import {getFormValues} from "../util";
import {type NamedGeoLocation} from "../../../common/domain/data";
import {Store} from "../../store";
import {CardSpinner} from "../../components/CardSpinner";

const THRESHOLD_AGE_SECONDS = 30_000;

type LocationViewProps = {
    model: Model,
    path: LocationPath,
};

export function LocationView({model, path}: LocationViewProps): ReactElement {
    if (!("geolocation" in navigator)) {
        return <Card><Card.Body>Your browser doesn't support geolocation</Card.Body></Card>;
    }

    if (model.geoLocation === null) {
        return <CardSpinner text={"Loading..."} />;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentStatus, setCurrentStatus] = useState<GeoStatus>({ type: "not-yet-set" });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (currentStatus.type === "error") return;
        if (currentStatus.type === "searching") return;
        if (currentStatus.type === 'position') {
            const secondsSinceLocation = (now().getTime() - currentStatus.pos.timestamp) / 1000;
            if (secondsSinceLocation < THRESHOLD_AGE_SECONDS) return;
        }

        setCurrentStatus({type: "searching"});

        void Promise.resolve()
            .then(async () => {
                try {
                    setCurrentStatus({type: "position", pos: await getCurrentLocation()});
                } catch (e) {
                    let message: string;
                    if (e instanceof GeolocationPositionError) {
                        message = e.message;
                    } else if (typeof e === "string") {
                        message = e;
                    } else {
                        message = "Unknown geolocation error";
                    }

                    if (path.component === "nearby") {
                        mutable.send({
                            type: "Notify",
                            notifications: [
                                {
                                    title: "Geo-location",
                                    level: "error",
                                    message: `${message} - using fallback location`,
                                }
                            ],
                        });

                        writeFragment(`#${model.geoLocation!.fallbackPath ?? DEFAULT_PATH_STR}`);
                    } else {
                        setCurrentStatus({type: "error", message})
                    }
                }
            });
    }, [currentStatus, model.geoLocation, path.component]);

    switch (currentStatus.type) {
        case "error":
            return <Card bg={"danger"} text={"dark"}><Card.Body>{currentStatus.message}</Card.Body></Card>;

        case "not-yet-set":
        case "searching":
            return <CardSpinner text={"Getting position..."} />;

        case "position":
            if (path.component === "nearby") {
                const redirect = geoRedirect(model, currentStatus.pos);
                console.log(`redirect = ${redirect}`)
                writeFragment(`#${redirect}`);

                return <CardSpinner text={"Redirecting..."} />;
            }
            break;

        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = currentStatus;
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Unexpected object: ${JSON.stringify(currentStatus)}`);
        }
    }

    const editing = model.geoLocation.locations.editing;
    if (editing) {
        return <Card>
            <Card.Body>
                <NamedLocationForm
                    initialName={editing.name}
                    initialPath={editing.path}
                    initialLatitude={editing.latitude.toString()}
                    initialLongitude={editing.longitude.toString()}
                    configuredLocations={model.geoLocation.locations.named}
                    submitText={"Edit"}
                    editingExisting={editing.name}
                    onSubmit={namedLocation => {

                        void domainTx(tx => handleCommands(tx, [{
                            type: "EditGeoLocation",
                            name: editing.name,
                            geoLocation: namedLocation,
                        }])).then(() => mutable.send({type: "EditNamedLocation", location: null}));
                    }}
                />
                <Button variant={"danger"} style={{marginTop: "1em"}} onClick={ev => {
                    ev.preventDefault();
                    mutable.send({type: "EditNamedLocation", location: null});
                }}>Cancel</Button>
            </Card.Body>
        </Card>;
    }

    return <Card>
        <Card.Body>
            <Card.Body key={"nearby"} style={{marginBottom: "2em"}}>
                <a key={"nearby"} href={`#${toUrlRepr({
                    type: "Location",
                    component: "nearby"
                })}`}>This link</a> is useful to bookmark; it'll send you to the most appropriate list for your current
                location.
            </Card.Body>
            <Form
                onSubmit={ev => {
                    ev.preventDefault();
                    const form = ev.target as HTMLFormElement;

                    const formVals = getFormValues(form);
                    const path = formVals["fallback-path"] as string;
                    void domainTx(tx => handleCommands(tx, [{
                        type: "SetFallbackGeoLocationPath",
                        path: path === "" ? null : path,
                    }]));
                }}
            >
                <Form.Group key={"fallback-path"}>
                    <Form.Label>Default path</Form.Label>
                    <Form.Control name={"fallback-path"} onChange={ev => {
                        ev.target.value = stripUpToHash(ev.target.value);
                    }} type={"text"}
                                  placeholder={DEFAULT_PATH_STR}
                                  defaultValue={model.geoLocation.fallbackPath === null ? "" : model.geoLocation.fallbackPath}></Form.Control>
                    <Form.Text className={"text-muted"}>If the "nearby" functionality can't find somewhere to send you,
                        it'll fall back to this.</Form.Text>
                </Form.Group>
                <Button variant={"primary"} type={"submit"} style={{marginTop: "1em"}}>{"Set"}</Button>
            </Form>
        </Card.Body>
        <Card.Body>
            <NamedLocationForm
                initialName={""}
                initialPath={""}
                initialLatitude={currentStatus.type === "position" ? currentStatus.pos.coords.latitude.toString() : ""}
                initialLongitude={currentStatus.type === "position" ? currentStatus.pos.coords.longitude.toString() : ""}
                configuredLocations={model.geoLocation.locations.named}
                submitText={"Submit"}
                onSubmit={namedGeoLocation => {
                    void domainTx(tx => handleCommands(tx, [{
                        type: "AddGeoLocation",
                        geoLocation: namedGeoLocation,
                    }]));
                }}
            />
        </Card.Body>
        <Card.Body>
            <Form key={"existing"}>
                <table>
                    <thead>
                    <tr key={"head"}>
                        <th>Name</th>
                        <th>Geoposition</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.values(model.geoLocation.locations.named)
                        .map((l, i) =>
                            <tr key={i}>
                                <td>{l.name}</td>
                                <td>{`${l.latitude}, ${l.longitude}`}</td>
                                <td width={model.windowSize < BootstrapWindowSize.Medium ? "100%" : undefined}>
                                    <Button
                                        size={"sm"} style={{marginTop: "1em"}}
                                        onClick={ev => {
                                            ev.preventDefault();
                                            mutable.send({type: "EditNamedLocation", location: l})
                                        }}
                                    >Edit</Button>
                                    <Button
                                        size={"sm"} style={{marginTop: "1em", marginLeft: "0.5em"}}
                                        onClick={ev => {
                                            ev.preventDefault();
                                            void domainTx(tx => handleCommands(tx, [{
                                                type: "RemoveGeoLocation",
                                                name: l.name,
                                            }]));
                                        }}
                                    >Delete</Button>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </Form>
        </Card.Body>
    </Card>;
}

function stripUpToHash(path: string): string {
    return path.replace(/.*#/, "");
}

type NamedLocationFormProps = {
    initialName: string,
    initialPath: string,
    initialLatitude: string,
    initialLongitude: string,
    configuredLocations: Readonly<{[key: string]: NamedGeoLocation}>,
    submitText: string,
    onSubmit: (namedGeoLocation: NamedGeoLocation) => void,
    editingExisting?: string,
};
function NamedLocationForm({initialName, initialPath, initialLatitude, initialLongitude, configuredLocations, submitText, onSubmit, editingExisting}: NamedLocationFormProps): ReactElement {
    const [name, setName] = useState(initialName);
    const [nameTouched, setNameTouched] = useState(false);
    const [path, setPath] = useState(initialPath);
    const [pathTouched, setPathTouched] = useState(false);
    const [latitude, setLatitude] = useState(initialLatitude);
    const [longitude, setLongitude] = useState(initialLongitude);

    function nameErrors(name: string): readonly string[] {
        const errs: string[] = [];

        if (name.length === 0) {
            errs.push("Please provide a name.");
        }

        if (name in configuredLocations && name !== editingExisting) {
            errs.push("Name must be unique.");
        }

        return errs;
    }

    function pathErrors(path: string): readonly string[] {
        const errs: string[] = [];

        if (path.length === 0) {
            errs.push("Please provide a path.");
        }

        if (!path.startsWith("/")) {
            errs.push("Path must start with a slash (/).");
        }

        return errs;
    }

    function overlapErrors(latitude: string, longitude: string): readonly string[] {
        try {
            const current = {
                name: "Location",
                path: "",
                latitude: parseFloat(latitude),
                longitude: parseFloat(longitude),
            };

         const namedGeoLocations = findOverlapping(THRESHOLD_SAVING_METRES, current, Object.values(configuredLocations))
             .map(n => n.name)
             .filter(n => n !== editingExisting);

         if (namedGeoLocations.length === 0) return [];

         return [`Lat/long overlaps with ${namedGeoLocations.join(", ")}`];
        } catch (_ignored) {
            return [];
        }
    }

    return <Form
        noValidate
        onSubmit={ev => {
            ev.preventDefault();
            ev.stopPropagation();

            if (nameErrors(name).length > 0 || pathErrors(path).length > 0 || overlapErrors(latitude, longitude).length > 0) {
                return;
            }

            onSubmit({
                name: name,
                path: path,
                latitude: parseFloat(latitude),
                longitude: parseFloat(longitude),
            });

            if (!editingExisting) {
                setName("");
                setPath("");
                setLatitude("");
                setLongitude("");
            }
        }}
    >
        <Form.Group key={"name"}>
            <Form.Label>Name</Form.Label>
            <InputGroup hasValidation>
                <Form.Control
                    name={"name"}
                    type={"text"}
                    placeholder={"name"}
                    value={name}
                    onFocus={() => setNameTouched(true)}
                    onChange={ev => setName(ev.target.value)}
                    required
                    isInvalid={nameTouched && nameErrors(name).length > 0}
                />
                <Form.Control.Feedback type="invalid">
                    {nameErrors(name).join(" ")}
                </Form.Control.Feedback>
            </InputGroup>
            <Form.Text className={"text-muted"}>e.g. "Home"/"Work"</Form.Text>
        </Form.Group>
        <Form.Group key={"path"}>
            <Form.Label>Path</Form.Label>
            <InputGroup hasValidation>
                <Form.Control
                    name={"path"}
                    type={"text"}
                    placeholder={"path"}
                    value={path}
                    onFocus={() => setPathTouched(true)}
                    onChange={ev => setPath(stripUpToHash(ev.target.value))}
                    required
                    isInvalid={pathTouched && pathErrors(path).length > 0}
                />
                <Form.Control.Feedback type="invalid">
                    {pathErrors(path).join(" ")}
                </Form.Control.Feedback>
            </InputGroup>
            <Form.Text className={"text-muted"}>e.g. "/graph/add"</Form.Text>
        </Form.Group>
        <Form.Group key={"latitude"}>
            <Form.Label>Latitude</Form.Label>
            <InputGroup hasValidation>
                <Form.Control
                    name={"latitude"}
                    type={"number"}
                    step={"any"}
                    min={-90}
                    max={90}
                    placeholder={"latitude"}
                    value={latitude}
                    required
                    isInvalid={overlapErrors(latitude, longitude).length > 0}
                    onChange={ev => setLatitude(ev.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                    {overlapErrors(latitude, longitude).join(" ")}
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
        <Form.Group key={"longitude"}>
            <Form.Label>Longitude</Form.Label>
            <InputGroup hasValidation>
                <Form.Control
                    name={"longitude"}
                    type={"number"}
                    step={"any"}
                    min={-180}
                    max={180}
                    placeholder={"longitude"}
                    value={longitude}
                    required
                    isInvalid={overlapErrors(latitude, longitude).length > 0}
                    onChange={ev => setLongitude(ev.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                    {overlapErrors(latitude, longitude).join(" ")}
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
        <Button variant={"primary"} type={"submit"} style={{marginTop: "1em"}}>{submitText}</Button>
    </Form>;
}

function geoRedirect(model: Model, position: GeolocationPosition): string {
    if (model.geoLocation === null) return DEFAULT_PATH_STR;

    const closest = findClosest(position, model.geoLocation.locations.named);
    if (closest !== undefined) {
        return closest.path;
    } else {
        return model.geoLocation.fallbackPath ?? DEFAULT_PATH_STR;
    }
}

async function getCurrentLocation(): Promise<GeolocationPosition> {
    const geolocationStub = Store.getItem("_geolocationStub");
    if (geolocationStub) {
        switch (geolocationStub.type) {
            case "geolocation-not-supported":
                throw "Browser doesn't support geolocation";

            case "geolocation-found":
                return geolocationStub.pos;

            default: {
                const _: never = geolocationStub;
                throw new Error(`Unexpected object: ${JSON.stringify(geolocationStub)}`);
            }
        }
    }

    return new Promise((res, rej) => navigator.geolocation.getCurrentPosition(
        res,
        rej,
        {
            // enableHighAccuracy: true,
            maximumAge: 30_000, // ms
            timeout: 5_000, // ms
        }
    ));
}

function findClosest(pos: GeolocationPosition, locations: Readonly<{[key: string]: NamedGeoLocation}>): NamedGeoLocation | undefined {
    const here = {
        name: "Here",
        path: "",
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
    };

    const options = findOverlapping(THRESHOLD_LOCATING_METRES, here, Object.values(locations));

    switch (options.length) {
        case 0: return undefined;
        case 1: return options[0] as NamedGeoLocation;
        default: {
            throw new Error(`Found too many location matches (${options.length})`);
        }
    }
}