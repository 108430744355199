import {type Id, type Lookup, ROOT, type Title, type TodoData} from "../../../../common/domain/data";
import React, {type ReactElement} from "react";
import {Breadcrumb, BreadcrumbItem, Col, Row} from "react-bootstrap";
import {ItemParentBadges} from "./widgets";
import {maybeUndef} from "../../../../common/util";

export function breadcrumb(href: string, key: string, title: Title, mtd?: TodoData): ReactElement {
    return (
        <BreadcrumbItem
            href={href}
            key={key}
            data-breadcrumb-id={key}
            linkProps={mtd
                ? {className: mtd.doneState}
                : {}
            }
        >
            {title}
        </BreadcrumbItem>
    );
}

export function grandparents(breadcrumbs: readonly Id[], grandparents: readonly Id[]): ReactElement | undefined {
    // last item (-1) in breadcrumbs is current item, so its parent is -2
    const currParent = maybeUndef(breadcrumbs[breadcrumbs.length - 2], ROOT, id => id as Lookup<Id>);

    return <Row key={"grandparents"}>
        <Col>
            <Breadcrumb>
                <ItemParentBadges parents={grandparents} currParent={currParent} />
            </Breadcrumb>
        </Col>
    </Row>;
}
