import React, {type ReactElement} from "react";
import {BootstrapWindowSize, type Model} from "../types";
import {Button, Card, Container, Nav, Navbar, NavbarBrand, NavLink, Toast, ToastContainer} from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {toUiRepr, toUrlRepr, type UrlPath,} from "../../common/urlpath";
import {writeFragment} from "../fragment";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import {copy, DEFAULT_PATH} from "../util";
import {emptyState} from "./util";
import {settingsView} from "./views/settings";
import {debugView} from "./views/debug";
import {mutable} from "../mutable";
import {LocationView} from "./views/location";
import {toolsView} from "./views/tools";
import {schedulingView} from "./views/scheduling";
import {clearItemTitleState, ItemTitle} from "../components/ItemTitle";
import {type Id, type Lookup, ROOT} from "../../common/domain/data";
import {helpView} from "./views/help";
import {GraphView} from "./views/graph";

export const TOP_SEARCH_PLACEHOLDER = "search";

export function view(model: Model): ReactElement {
    const toolsUrlPath: UrlPath = {type: "Tools"};
    const locationUrlPath: UrlPath = {type: "Location"};
    const schedulingUrlPath: UrlPath = {type: "Scheduling"};
    const settingsUrlPath: UrlPath = {type: "Settings"};
    const helpUrlPath: UrlPath = {type: "Help"};
    return (
        <Container fluid={true}>
            <Navbar expand={"lg"} key={"nav"}>
                <NavbarCollapse id={"basic-navbar-nav"} key={"collapse"}>
                    <NavbarBrand href={`#${toUrlRepr(DEFAULT_PATH)}`} key={"brand"}>mapdone</NavbarBrand>
                    <Nav className={"mr-auto"} key={"nav"} activeKey={model.path.type}>
                        <NavLink href={`#${toUrlRepr(locationUrlPath)}`} key={"location"} eventKey={"Location"}>{toUiRepr(locationUrlPath)}</NavLink>
                        <NavLink href={`#${toUrlRepr(schedulingUrlPath)}`} key={"scheduling"} eventKey={"Scheduling"}>{toUiRepr(schedulingUrlPath)}</NavLink>
                        <NavLink href={`#${toUrlRepr(toolsUrlPath)}`} key={"tools"} eventKey={"Tools"}>{toUiRepr(toolsUrlPath)}</NavLink>
                        <NavLink href={`#${toUrlRepr(settingsUrlPath)}`} key={"settings"} eventKey={"Settings"}>{toUiRepr(settingsUrlPath)}</NavLink>
                        <NavLink href={`#${toUrlRepr(helpUrlPath)}`} key={"help"} eventKey={"Help"}>{toUiRepr(helpUrlPath)}</NavLink>
                    </Nav>
                </NavbarCollapse>
                {(() => {
                    if (!window.matchMedia('(display-mode: standalone)').matches) return;

                    return <>
                        <Button variant={"sm"} className={"btn-link"} onClick={ev => {
                            ev.preventDefault();
                            history.back();
                        }}><i
                            className={`bi bi-arrow-left`}
                            title={"Back"}
                        /></Button>
                        <Button variant={"sm"} className={"btn-link"} onClick={ev => {
                            ev.preventDefault();
                            history.forward();
                        }}><i
                            className={`bi bi-arrow-right`}
                            title={"Forward"}
                        /></Button>
                    </>;
                })()}
                {(() => {
                    if (!("geolocation" in navigator)) return;

                    return <Button variant={"sm"} className={"nearby btn-link"} href={`#${toUrlRepr({type: "Location", component: "nearby"})}`}>
                        <i
                            className={`bi bi-geo-alt-fill`}
                            title={"Nearby"}
                        />
                    </Button>;
                })()}
                {(() => {
                    if (!window.matchMedia('(display-mode: standalone)').matches) return;

                    return <Button variant={"sm"} className={"copy-link btn-link"} onClick={ev => {
                        ev.preventDefault();
                        void copy(window.location.href, {message: "Link copied to clipboard"});
                    }}><i
                        className={`bi bi-link-45deg`}
                        title={"Copy link to current page"}
                    /></Button>;
                })()}
                {(() => {
                    const commandMessage = model.commandGroupCount === 0
                        ? ""
                        : `, ${model.commandGroupCount} command${model.commandGroupCount === 1 ? "" : "s"} in queue`;

                    let tooltip = undefined;
                    switch (model.connectionStatus) {
                        case "not-yet-connected":
                            tooltip = "Not yet connected to the server";
                            break;
                        case "connected":
                            tooltip = "Connected to the server"
                            break;
                        case "disconnected-retrying":
                            tooltip = "Disconnected from the server - retrying"
                            break;
                        default: {
                            const _: never = model.connectionStatus;
                            throw new Error(`Unexpected object: ${JSON.stringify(model.connectionStatus)}`);
                        }
                    }

                    tooltip += commandMessage;

                    const circleType = model.commandGroupCount === 0
                        ? "bi-check-circle-fill"
                        : "bi-filter-circle-fill";

                    switch (model.connectionStatus) {
                        case "not-yet-connected":
                        case "connected":
                        case "disconnected-retrying":
                            return <i
                                className={`bi ${circleType} ws-status ${model.connectionStatus}`}
                                title={tooltip}
                            />;

                        default: {
                            const _: never = model.connectionStatus;
                            throw new Error(`Unexpected object: ${JSON.stringify(model.connectionStatus)}`);
                        }
                    }
                })()}
                <ItemTitle
                    initialState={{defaultHighlight: 0}}
                    componentClassName={model.windowSize < BootstrapWindowSize.Medium ? "w-50" : "w-75"}
                    placeholder={TOP_SEARCH_PLACEHOLDER}
                    tabIndex={1}
                    idBlockList={model.graph ? [model.graph.breadcrumbs.slice(-1)[0]?.id as undefined | Lookup<Id> ?? ROOT] : []}
                    onItemChosen={(chosen, state) => {
                        clearItemTitleState(state);

                        if (chosen === null) return;

                        writeFragment(`#${toUrlRepr({
                            type: "Graph",
                            breadcrumbs: chosen.id === ROOT
                                ? []
                                : [chosen.id as Id]
                        })}`)
                    }}
                />
                <NavbarToggle aria-controls={"basic-navbar-nav"} key={"toggle"}/>
            </Navbar>
            <ToastContainer id="toasts" key="toasts" className="p-3" containerPosition={"fixed"} position="top-end">
                {
                    model.notifications.map((notification, i) => {
                        let timer: undefined | number;
                        if (notification.timeoutSeconds !== undefined) {
                            timer = window.setTimeout(() => mutable.send({type: "DismissNotification", notification}), notification.timeoutSeconds * 1000);
                        }

                        return <Toast
                            key={i}
                            className={`notification-level-${notification.level}`}
                            onClose={() => {
                                if (timer !== undefined) {
                                    window.clearTimeout(timer);
                                }
                                mutable.send({type: "DismissNotification", notification});
                            }}
                        >
                            <Toast.Header>
                                <strong className="me-auto">{notification.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{notification.message}</Toast.Body>
                        </Toast>;
                    })
                }
            </ToastContainer>
            {
                ((() => {
                    switch (model.path.type) {
                        case "Empty":
                            return emptyState();

                        case "NotFound":
                            return <Card border={"danger"} text={"white"}>
                                <Card.Header>Not found!</Card.Header>
                                <Card.Body>
                                    <Card.Text>Couldn't find {model.path.path}</Card.Text>
                                </Card.Body>
                            </Card>;

                        case "Help":
                            return helpView(model.path);

                        case "Tools":
                            return toolsView(model, model.path);

                        case "Location": {
                            return <LocationView model={model} path={model.path} />;
                        }

                        case "Scheduling": {
                            return schedulingView(model, model.path);
                        }

                        case "Settings": {
                            return settingsView(model, model.path);
                        }

                        case "LoginError": {
                            return <Card border={"danger"} text={"white"}>
                                <Card.Header>Login error</Card.Header>
                                <Card.Body>
                                    <Card.Text>{(() => {
                                        switch (model.path.error) {
                                            case "token-missing":
                                                return <>The magic token was missing. Please try clicking the link in your email again.</>;
                                            case "token-invalid":
                                                return <>The magic token is invalid. Please try clicking the link in your email again.</>;
                                            case "token-expired":
                                                return <>The magic token has expired, please <a href={`#${toUrlRepr({type: "Settings", component: "login"})}`}>login again</a>.</>;

                                            default: {
                                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                const _: never = model.path.error;
                                                // noinspection ExceptionCaughtLocallyJS
                                                throw new Error(`Unexpected object: ${JSON.stringify(model.path.error)}`);
                                            }
                                        }
                                    })()}</Card.Text>
                                </Card.Body>
                            </Card>;
                        }

                        case "Debug": {
                            return debugView(model, model.path);
                        }

                        case "Graph":
                            return <GraphView model={model} graphModel={model.graph} path={model.path} />;

                        default: {
                            const _: never = model.path;
                            throw new Error(`Unexpected object: ${JSON.stringify(model.path)}`);
                        }
                    }
                })())
            }
        </Container>
    );
}
