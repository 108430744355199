import {BootstrapWindowSize, type IxItemThin, type Model, type Notification} from "./types";
import {toUrlRepr, type UrlPath} from "../common/urlpath";
import {type Id} from "../common/domain/data";
import {lru} from "./lru";
import {mutable} from "./mutable";
import {now} from "../common/datetime";
import {DAY_IN_MS} from "../common/constants";

export const DEFAULT_PATH: UrlPath = {
    type: "Graph",
    breadcrumbs: [],
};

export const DEFAULT_PATH_STR = toUrlRepr(DEFAULT_PATH);

export function getWindowSize(): BootstrapWindowSize {
    const w = window.innerWidth;
    if (w >= 1200) {
        return BootstrapWindowSize.ExtraLarge;
    } else if (w >= 992) {
        return BootstrapWindowSize.Large;
    } else if (w >= 768) {
        return BootstrapWindowSize.Medium;
    } else if (w >= 576) {
        return BootstrapWindowSize.Small;
    } else {
        return BootstrapWindowSize.ExtraSmall;
    }
}

export function blurEverything() {
    if (document?.activeElement instanceof HTMLElement) {
        document?.activeElement.blur();
    }
}

export function debugValue<T>(x: T): T {
    debugger;
    return x;
}

export function postItemRemoval(removedItems: {[key: Id]: IxItemThin}): void {
    Object.entries(removedItems).forEach(([id, item]) => {
        lru.delete(id as Id);
        try {
            mutable.search.remove(item);
        } catch (ignored) {
            // do nothing
        }
    });
}

export function addNotifications(model: Model, notifications: Notification[]): Model {
    return {...model, notifications: [...notifications, ...model.notifications]};
}

export async function copy(text: string, notification: Partial<Notification> & {message: string}): Promise<void> {
    return navigator.clipboard.writeText(text)
        .then(() => mutable.send({
            type: "Notify",
            notifications: [{
                level: "info",
                title: "Copied",
                timeoutSeconds: 3,
                ...notification,
            }],
        }))
        .catch((err: any) => mutable.send({
            type: "Notify",
            notifications: [{
                level: "error",
                title: "Could not copy",
                message: typeof err === "string" ? err : JSON.stringify(err),
            }],
        }));
}

export function msUntilMidnight(): number {
    const d = now();
    return DAY_IN_MS - (d.getTime() - d.setHours(0,0,0,0));
}