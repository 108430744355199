import {
    type Child,
    type ChildAdded,
    type ChildAttached,
    type ChildrenReordered,
    type Id,
    type Lookup,
    type Parent,
    ROOT
} from "../common/domain/data";
import {
    type IxItemChild,
    type IxItemGrandparent,
    type IxItemParent,
    type IxItemThin,
    ROOT_PARENT, ROOT_THIN
} from "./types";
import {isSomething} from "../common/util";
import {FancyTransaction} from "./db";
import {type UiEvent} from "../common/domain/logic";
import {lru} from "./lru";

export async function getByIds(tx: FancyTransaction, ids: readonly (Lookup<Id>)[]): Promise<(Lookup<IxItemThin> | undefined)[]> {
    return Promise.all(ids.map(id => {
        if (id === ROOT) {
            return Promise.resolve(ROOT_THIN);
        }

        const item = lru.get(id as Id);
        if (item) {
            // deep copy to avoid shared mutable state
            return Promise.resolve(JSON.parse(JSON.stringify(item)) as Lookup<IxItemThin>);
        }

        return tx.table("items").get(id as Id) as Promise<Lookup<IxItemThin>>;
    }));
}

export async function getChildren(tx: FancyTransaction, ids: readonly Lookup<Id>[]): Promise<(IxItemChild | undefined)[]> {
    const children = await getByIds(tx, ids);

    let childParentIds: Parent<Id>[] = [];
    const childParentCounts = [];
    for (const child of children) {
        childParentCounts.push(child ? child.parents.length : 0);
        if (child) {
            childParentIds = [...childParentIds, ...child.parents];
        }
    }
    const childParents = await getByIds(tx, childParentIds);
    let start = 0;

    const childrenExpanded: (IxItemChild | undefined)[] = [];
    for (let i = 0; i < childParentCounts.length; i++) {
        const count = childParentCounts[i] as number;
        const child = children[i];
        if (child) {
            const parents: Parent<IxItemThin>[] = [];
            for (let j = start; j < start + count; j++) {
                const childParent = childParents[j];
                if (childParent) {
                    parents.push(childParent as Parent<IxItemThin>);
                }
            }
            childrenExpanded[i] = {
                ...child,
                type: "IxItemChild",
                parents,
            };
        }
        start += count;
    }

    return childrenExpanded;
}

export async function expandEvent(tx: FancyTransaction, ev: UiEvent<Id, Id>): Promise<UiEvent<IxItemParent, IxItemChild>> {
    switch (ev.type) {
        case "ChildAdded":
            return {
                ...ev,
                ...await expandParentAndChild(tx, ev),
            } as ChildAdded<IxItemParent, IxItemChild>;

        case "ChildAttached":
            return {
                ...ev,
                ...await expandParentAndChild(tx, ev),
            } as ChildAttached<IxItemParent, IxItemChild>;

        case "ChildrenReordered": {
            if (ev.parent === ROOT) {
                const cs = await getByIds(tx, ev.children);

                const allParentIds: Parent<Id>[] = [];
                const childParentCounts: number[] = [];
                for (const c of cs) {
                    if (!c) {
                        throw new Error("Invalid data");
                    }
                    allParentIds.push(...(c.parents));
                    childParentCounts.push(c.parents.length);
                }

                const allParents = await getByIds(tx, allParentIds);

                let offset = 0;
                for (let i = 0; i < childParentCounts.length; i++) {
                    const childParentCount = childParentCounts[i] as number;
                    const childParents = allParents.slice(offset, childParentCount).filter(isSomething);
                    offset += childParentCount;
                    toIxItemChildInPlace(cs[i] as IxItemThin, childParents as IxItemThin[]);
                }

                return {
                    ...ev,
                    parent: ROOT_PARENT,
                    children: cs,
                } as ChildrenReordered<IxItemParent, IxItemChild>;
            } else {
                const all = await getByIds(tx, [ev.parent, ...ev.children]);
                const p = all[0] as IxItemThin | undefined;
                const cs = all.slice(1) as (IxItemThin | undefined)[];
                if (!p) {
                    throw new Error("Invalid data");
                }

                const allParentIds: Parent<Id>[] = [...p.parents];
                const childParentCounts: number[] = [];
                for (const c of cs) {
                    if (!c) {
                        throw new Error("Invalid data");
                    }
                    allParentIds.push(...(c.parents));
                    childParentCounts.push(c.parents.length);
                }

                const allParents = await getByIds(tx, allParentIds);
                const grandparents = allParents.slice(0, p.parents.length).filter(isSomething) as IxItemGrandparent[];

                let offset = 1;
                for (let i = 0; i < childParentCounts.length; i++) {
                    const childParentCount = childParentCounts[i] as number;
                    const childParents = allParents.slice(offset, childParentCount).filter(isSomething);
                    offset += childParentCount;

                    // TODO: needlessly hacky
                    toIxItemChildInPlace(cs[i] as IxItemThin, childParents as IxItemThin[]);
                }

                return {
                    ...ev,
                    parent: toIxItemParentInPlace(p, grandparents) as Parent<IxItemParent>,
                    children: cs,
                } as ChildrenReordered<IxItemParent, IxItemChild>;
            }
        }

        case "ChildrenReorderedRelative":
        case "ChildDetached":
        case "LayerRemoved":
        case "ChildMassDetached":
        case "DoneEdited":
        case "TitleEdited":
        case "DetailEdited":
        case "CompletableEdited":
        case "NewChildLocationUpdated":
        case "FallbackGeoLocationPathSet":
        case "GeoLocationAdded":
        case "GeoLocationEdited":
        case "GeoLocationRemoved":
        case "ScheduledAdded":
        case "ScheduledEdited":
        case "ScheduledRemoved":
        case "ScheduleExecuted":
        case "AutomatedChildDetached":
        case "AutomatedDoneEdited":
        case "AutomatedCountAggregation":
            return ev;

        default: {
            // NB. these cases need adding only if the event needs fleshing out with full-fat items
            const _: never = ev;
            throw new Error(`Unexpected object: ${JSON.stringify(ev)}`);
        }
    }
}

async function expandParentAndChild(tx: FancyTransaction, ev: {parent: Parent<Id>, child: Child<Id>}): Promise<{parent: Parent<IxItemParent>, child: Child<IxItemChild>}> {
    if (ev.parent === ROOT) {
        const [c] = await getByIds(tx, [ev.child]);
        if (!c) {
            throw new Error("Invalid data");
        }
        const childParents = (await getByIds(tx, c.parents)).filter(isSomething);

        return {
            parent: ROOT_PARENT,
            child: toIxItemChildInPlace(c, childParents as IxItemThin[]) as Child<IxItemChild>,
        };
    } else {
        const [p, c] = await getByIds(tx, [ev.parent, ev.child]);
        if (!p || !c) {
            throw new Error("Invalid data");
        }
        const allParents = await getByIds(tx, [...p.parents, ...c.parents]);
        const grandparents = allParents.slice(0, p.parents.length).filter(isSomething) as IxItemGrandparent[];
        const childParents = allParents.slice(p.parents.length).filter(isSomething) as IxItemThin[];

        return {
            parent: toIxItemParentInPlace(p, grandparents) as Parent<IxItemParent>,
            child: toIxItemChildInPlace(c, childParents) as Child<IxItemChild>,
        };
    }
}

function toIxItemParentInPlace(item: IxItemThin, grandparents: IxItemThin[]): IxItemParent {
    const parent: any = item;
    parent.type = "IxItemParent";
    parent.parents = grandparents;

    return parent as IxItemParent;
}

function toIxItemChildInPlace(item: IxItemThin, parents: IxItemThin[]): IxItemChild {
    const parent: any = item;
    parent.type = "IxItemChild";
    parent.parents = parents;

    return parent as IxItemChild;
}
