import React, {type PropsWithChildren, type ReactElement} from "react";
import {Modal, ModalBody, ModalTitle} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";

type ModelWidgetProps = PropsWithChildren<{
    title: string,
    onHide: () => void,
}>;

export function ModalWidget({title, onHide, children}: ModelWidgetProps): ReactElement {
    return <Modal
        show={true}
        // NB. for some reason autoFocus in forms isn't working, so here's a JS hack
        onEntered={el => el.querySelector("input")?.focus()}
        onHide={onHide}
    >
        <ModalHeader key={"header"} closeButton={true}>
            <ModalTitle key={"title"}>{title}</ModalTitle>
        </ModalHeader>
        <ModalBody key={"body"}>
            {children}
        </ModalBody>
    </Modal>;
}