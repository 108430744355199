export function deepEqual(a: any, b: any): boolean {
    if (a === b) return true;

    if (a === null || b === null) return false; // typeof null === "object" which is a bit annoying

    const ta: string = typeof a;
    const tb: string = typeof b;

    if (ta !== tb) return false;

    // if it's another type let's assume that the initial === validated it
    if (ta !== "object") return false;

    if (Object.keys(a).length !== Object.keys(b).length) return false;

    for (const k in a) {
        if (!deepEqual(a[k], b[k])) return false;
    }

    return true;
}