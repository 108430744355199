export type ISO8601_DateTime = string & { readonly __tag_ISO8601_DateTime: unique symbol };
export type ISO8601_Date = string & { readonly __tag_ISO8601_Date: unique symbol };
export type ISO8601_DateWithoutYear = string & { readonly __tag_ISO8601_DateWithoutYear: unique symbol };

export type DayName = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
export type DayNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31;
export type MonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export function serializeUtcIsoDateTime(date: Date): ISO8601_DateTime {
    return date.toISOString() as ISO8601_DateTime;
}

export function parseIsoDateTime(dateTime: ISO8601_DateTime): Date {
    const date = new Date(dateTime);
    if (isNaN(date.valueOf())) {
        throw new Error(`Invalid date format: ${dateTime}`);
    }
    return date;
}

export function parseIsoDate(date: ISO8601_Date): Date {
    return new Date(date);
}

export function getLocalDayName(date: Date): DayName {
    switch (date.getDay()) {
        case 0: return "sun";
        case 1: return "mon";
        case 2: return "tue";
        case 3: return "wed";
        case 4: return "thu";
        case 5: return "fri";
    }

    return "sat";
}

export function getDayIndex(day: DayName): number {
    switch (day) {
        case "sun": return 0;
        case "mon": return 1;
        case "tue": return 2;
        case "wed": return 3;
        case "thu": return 4;
        case "fri": return 5;
    }

    return 6;
}

export function now(): Date {
    if (typeof localStorage !== 'undefined') {
        const stub = localStorage.getItem("__dateStub");
        if (stub === null) {
            return new Date();
        } else {
            return new Date(stub);
        }
    } else {
        return new Date();
    }
}

export function nowISO(): ISO8601_DateTime {
    return serializeUtcIsoDateTime(now());
}

export function dateTimeToDate(dateTime: ISO8601_DateTime): ISO8601_Date {
    return dateTime.replace(/T.*$/, "") as ISO8601_Date;
}

export function dateToDateWithoutYear(date: ISO8601_Date): ISO8601_DateWithoutYear {
    return date.replace(/^.+?-/, "") as ISO8601_DateWithoutYear;
}

export type DurationGranularity = "s" | "m" | "h" | "d";
export function addDuration(reference: Date, granularity: DurationGranularity, count: number): Date {
    const d = new Date(reference);

    switch (granularity) {
        case "s":
            d.setSeconds(d.getSeconds() + count);
            break;
        case "m":
            d.setMinutes(d.getMinutes() + count);
            break;
        case "h":
            d.setHours(d.getHours() + count);
            break;
        case "d":
            d.setDate(d.getDate() + count);
            break;
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = granularity;
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Unexpected object: ${JSON.stringify(granularity)}`);
        }
    }

    return d;
}

export function toIsoDateString(date: Date): ISO8601_Date {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}` as ISO8601_Date
}