import {type NamedGeoLocation} from "./data";

export const THRESHOLD_SAVING_METRES = 1_000;
export const THRESHOLD_LOCATING_METRES = 500;

// from https://www.movable-type.co.uk/scripts/latlong.html
export function distanceMetres(coords1: NamedGeoLocation, coords2: NamedGeoLocation): number {
    const lat1 = coords1.latitude;
    const lat2 = coords2.latitude;
    const lon1 = coords1.longitude;
    const lon2 = coords2.longitude;

    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    const d = R * c; // in metres

    return d;
}

export function findOverlapping(threshold: number, search: NamedGeoLocation, geoLocations: readonly NamedGeoLocation[]): readonly NamedGeoLocation[] {
    return geoLocations
        .filter(l => distanceMetres(l, search) < threshold)
}