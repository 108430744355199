import React, {type Dispatch, type ReactElement, type Ref, type SetStateAction, useRef, useState} from "react";
import {Button, Dropdown, Form, type FormControlProps, InputGroup} from "react-bootstrap";
import {doNothing} from "../../common/util";

type UsedFormControlProps = "value"|"aria-expanded"|"readOnly"|"onFocus"|"onMouseDown"|"onChange"|"style";
export type ComboboxProps<Item> = Omit<FormControlProps, UsedFormControlProps> & {
    initialState?: InitialComboboxState,
    state?: ComboboxState,
    inputRef?: Ref<HTMLInputElement>,
    itemToElement: (item: Item) => ReactElement,
    itemToText: (item: Item) => string,
    input: string,
    items: readonly Item[],
    chosenItem?: null | Item,
    onInputChange: (input: string) => void,
    onItemChosen: (item: null | Item) => void,
    onEscape?: (e: React.KeyboardEvent<HTMLElement>) => void,

    componentClassName?: string,
    componentStyle?: React.CSSProperties,

    inputClassName?: string,
    inputStyle?: React.CSSProperties,

    itemClassName?: (item: Item) => string,
    itemStyle?: (item: Item) => React.CSSProperties,

    [key: string]: any,
};

export type ReactState<S> = [S, Dispatch<SetStateAction<S>>];

export type ComboboxState = {
    defaultHighlightedItem: null | number,
    highlightedItem: ReactState<null | number>,
}

export type InitialComboboxState = {
    defaultHighlight?: null | number,
}
export function useComboboxState({defaultHighlight = null}: InitialComboboxState = {}): ComboboxState {
    return {
        defaultHighlightedItem: defaultHighlight,
        highlightedItem: useState<null | number>(defaultHighlight),
    };
}

export function clearComboboxState(state: ComboboxState) {
    const [_highlightedItem, setHighlightedItem] = state.highlightedItem;

    setHighlightedItem(state.defaultHighlightedItem);
}

export function Combobox<Item>(
    {
        initialState = {},
        // eslint-disable-next-line react-hooks/rules-of-hooks
        state = useComboboxState(initialState),
        inputRef,
        input,
        onInputChange = doNothing,
        items,
        chosenItem = null,
        itemToElement,
        itemToText,
        onItemChosen,
        onEscape = doNothing,

        componentClassName = "",
        componentStyle = {},

        inputClassName = "",
        inputStyle = {},

        itemClassName = _item => "",
        itemStyle = _item => ({}),

        ...props
    }: ComboboxProps<Item>
): ReactElement {
    const [manuallyExpanded, setManuallyExpanded] = useState(false);
    const [focused, setFocused] = useState(false);
    const [highlightedItem, setHighlightedItem] = state.highlightedItem;
    const referenceInputGroupElement = useRef<HTMLDivElement>(null);
    const referenceComponentElement = useRef<HTMLDivElement>(null);

    const readonlyMode = chosenItem !== null;

    const expanded = (() => {
        if (readonlyMode) {
            return false;
        }

        if (manuallyExpanded) {
            return true;
        }

        if (!focused) {
            return false;
        }

        if (input.length > 0) {
            return true;
        }

        if (highlightedItem !== null) {
            return true;
        }

        return false;
    })();

    return <div
        ref={referenceComponentElement}
        className={`dropdown ${expanded && "show"} ${componentClassName}`}
        onBlur={e => {
            if (blurIsAwayFromOverallComponent(e)) {
                setHighlightedItem(state.defaultHighlightedItem);
                setFocused(false);
            }
        }}
        onDoubleClick={() => {
            if (!chosenItem) return;

            // re-search
            onInputChange(itemToText(chosenItem));

            // un-choose an item
            onItemChosen(null);
        }}
        style={componentStyle}
    >
        <InputGroup
            ref={referenceInputGroupElement}
            onKeyDown={e => {
                switch (e.key) {
                    case "ArrowDown":
                        handleDown(e);
                        break;
                    case "ArrowUp":
                        handleUp(e);
                        break;
                    case "Enter":
                        handleEnter(e);
                        break;
                    case "Escape":
                        handleEscape(e);
                        break;
                }
            }}
        >
            <Form.Control
                {...props}
                ref={inputRef}
                value={input}
                aria-expanded={expanded}
                readOnly={readonlyMode}
                onFocus={() => {
                    setFocused(true);
                }}
                onMouseDown={e => {
                    if (!readonlyMode) return;

                    // stop text highlighting on double-click
                    if (e.detail > 1) {
                        e.preventDefault();
                    }
                }}
                onChange={e => {
                    onInputChange(e.target.value);
                }}
                className={inputClassName}
                style={{
                    backgroundColor: chosenItem === null ? "inherit" : "#676767",
                    ...inputStyle,
                }}
            />
            <Button
                className={"dropdown-toggle"}
                disabled={chosenItem !== null}
                onClick={_e => {
                    setManuallyExpanded(!manuallyExpanded);
                }}
            />
        </InputGroup>
        {renderDropdown(expanded)}
    </div>;

    function renderDropdown(expanded: boolean): React.ReactElement | undefined {
        if (!expanded) return undefined;

        return <div
            aria-labelledby="dropdown-item-button"
            data-bs-popper="static"
            className="dropdown-menu show"
            style={{
                marginLeft: `${referenceInputGroupElement.current?.offsetLeft ?? "250"}px`,
                width: `${referenceInputGroupElement.current?.offsetWidth ?? "250"}px`,
            }}
        >
            {renderDropdownContent()}
        </div>
    }

    function renderDropdownContent(): ReactElement | ReactElement[] {
        return items.length === 0
            ? <Dropdown.ItemText>No results</Dropdown.ItemText>
            : items
                .map((item, i) =>
                    <Dropdown.Item
                        key={i}
                        active={highlightedItem === i}
                        onClick={() => {
                            handleItemChosen(item);
                        }}
                        className={itemClassName(item)}
                        style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            ...itemStyle(item)
                        }}
                    >
                        {itemToElement(item)}
                    </Dropdown.Item>);
    }

    function blurIsAwayFromOverallComponent(e: React.FocusEvent<HTMLDivElement>) {
        let el = e.relatedTarget;
        while (el !== null) {
            if (el === referenceComponentElement.current) return false;
            el = el.parentElement;
        }

        return true;
    }

    function handleDown(e: React.KeyboardEvent<HTMLElement>): void {
        if (items.length === 0) {
            return;
        } else if (highlightedItem === null) {
            setHighlightedItem(0);
        } else if (highlightedItem === items.length - 1) {
            setHighlightedItem(0);
        } else {
            setHighlightedItem(highlightedItem + 1);
        }

        e.preventDefault();
    }

    function handleUp(e: React.KeyboardEvent<HTMLElement>): void {
        if (items.length === 0) {
            return;
        } else if (highlightedItem === null) {
            setHighlightedItem(items.length - 1);
        } else if (highlightedItem === 0) {
            setHighlightedItem(items.length - 1);
        } else {
            setHighlightedItem(highlightedItem - 1);
        }

        e.preventDefault();
    }

    function handleEnter(e: React.KeyboardEvent<HTMLElement>): void {
        if (highlightedItem === null) return;
        e.preventDefault(); // don't submit a form we're in

        const item = items[highlightedItem];
        if (item) {
            handleItemChosen(item);
        } else {
            onItemChosen(null);
        }
    }

    function handleEscape(e: React.KeyboardEvent<HTMLElement>): void {
        setHighlightedItem(state.defaultHighlightedItem);
        setManuallyExpanded(false);
        onEscape(e);
    }

    function handleItemChosen(item: Item): void {
        onItemChosen(item);
        if (document?.activeElement instanceof HTMLElement) {
            document?.activeElement.blur();
        }
    }
}