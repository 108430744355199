import {type DayName, type DayNumber, type ISO8601_Date, type ISO8601_DateTime, type ISO8601_DateWithoutYear} from "../datetime";
import {type UUID_V4} from "../uuid";

export type Id = string & { readonly __tag_Id: unique symbol };

export type Lookup<T extends object> = null | T;
export const ROOT = null;

export type Parent<T extends object> = Lookup<T>;
export type Child<T extends object> = T & { readonly __tag_Child: unique symbol };

export type Title = string & { readonly __tag_Title: unique symbol };
export type Detail = string & { readonly __tag_Detail: unique symbol };
export type NoteData = {
    title: Title,
    detail?: Detail,
};

export type DoneState = "done" | "todo";
export type TodoData = {
    doneState: DoneState,
    updated: ISO8601_DateTime,
};

export type Completable = "is-completable" | "is-not-completable";

export type Item<P extends object, C extends object> = {
    created: ISO8601_DateTime,
    parents: readonly Parent<P>[],
    newChildLocation: Location,
    children: readonly Child<C>[],
    noteData: NoteData,
    todoData?: TodoData,
    aggregatedSubGraph?: AggregatedItemSubgraphData,
};

// NB. this is mutable and gets copied, so be careful adding new fields with objects!
export type AggregatedItemSubgraphData = {
    nodeCount: number,
    todoCount: number,

    // "limited" means it's stopped by "non-completable" parents
    limitedTodoCount: number,
    limitedDoneCount: number,
};

export type Entrypoint<C extends object> = {
    newChildLocation: Location,
    children: readonly Child<C>[],
}

export type IndexedItem<Parent extends object, Child extends object, Identifier = Id> = { id: Identifier, deleted?: ISO8601_DateTime } & Item<Parent, Child>;

export type RelativePosition = "above" | "below";

export type Location = "top" | "bottom";
export type Source = "schedule";

export const DEFAULT_LOCATION: Location = "bottom";

export type Command
    = AddChild
    | AttachChild
    | DetachChild
    | RemoveLayer
    | MassDetachChild
    | MoveChild
    | EditDone
    | EditTitle
    | EditDetail
    | EditCompletable
    | ReorderChild
    | UpdateNewChildLocation
    | SetGeoLocationFallbackPath
    | AddGeoLocation
    | EditGeoLocation
    | RemoveGeoLocation
    | AddScheduled
    | EditScheduled
    | RemoveScheduled
    | RecordScheduleExecuted
    ;

export type AddChild = {
    type: "AddChild",
    parent: Parent<Id>,
    child: Child<Id>,
    noteData: NoteData,
    todoData?: Omit<TodoData, "updated">,
    newChildLocation?: Location,
    location?: Location,
    source?: Source,
};

export type AttachChild = {
    type: "AttachChild",
    parent: Parent<Id>,
    child: Child<Id>,
    location?: Location,
    source?: Source,
};

export type DetachChild = {
    type: "DetachChild",
    parent: Parent<Id>,
    child: Child<Id>,
};

export type RemoveLayer = {
    type: "RemoveLayer",
    parent: Parent<Id>,
    child: Child<Id>,
};

export type MassDetachChild = {
    type: "MassDetachChild",
    parent: Parent<Id>,
    child: Child<Id>,
};

export type MoveChild = {
    type: "MoveChild",
    fromParent: Parent<Id>,
    toParent: Parent<Id>,
    child: Child<Id>,
};

export type EditDone = {
    type: "EditDone",
    item: Id,
    from: DoneState,
    to: DoneState,
    source?: Source,
};

export type EditTitle = {
    type: "EditTitle",
    item: Id,
    from: Title,
    to: Title,
};

export type EditDetail = {
    type: "EditDetail",
    item: Id,
    from?: Detail,
    to?: Detail,
};

export type EditCompletable = {
    type: "EditCompletable",
    item: Id,
    from: Completable,
    to: Completable,
    source?: Source,
};

export type ReorderChild = {
    type: "ReorderChild",
    parent: Parent<Id>,
    child: Child<Id>,
    position: RelativePosition,
    sibling: Child<Id>,
};

export type UpdateNewChildLocation = {
    type: "UpdateNewChildLocation",
    parent: Parent<Id>,
    location: Location,
};

export type SetGeoLocationFallbackPath = {
    type: "SetFallbackGeoLocationPath",
    path: string | null,
};

export type AddGeoLocation = {
    type: "AddGeoLocation",
    geoLocation: NamedGeoLocation,
};

export type EditGeoLocation = {
    type: "EditGeoLocation",
    name: string,
    geoLocation: NamedGeoLocation,
};

export type RemoveGeoLocation = {
    type: "RemoveGeoLocation",
    name: string,
};

export type AddScheduled = {
    type: "AddScheduled",
    scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>,
};

export type EditScheduled = {
    type: "EditScheduled",
    scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>,
};

export type RemoveScheduled = {
    type: "RemoveScheduled",
    id: ScheduledId,
};

export type RecordScheduleExecuted = {
    type: "RecordScheduleExecuted",
    date: ISO8601_DateTime,
};

export type Event<P extends object, C extends object>
    = ChildAdded<P, C>
    | ChildAttached<P, C>
    | ChildDetached
    | LayerRemoved
    | ChildMassDetached
    | DoneEdited
    | TitleEdited
    | DetailEdited
    | CompletableEdited
    | ChildrenReordered<P, C>
    | ChildrenReorderedRelative
    | NewChildLocationUpdated
    | GeoLocationFallbackPathSet
    | GeoLocationAdded
    | GeoLocationEdited
    | GeoLocationRemoved
    | ScheduledAdded
    | ScheduledEdited
    | ScheduledRemoved
    | ScheduleExecuted
    ;

export type ChildAdded<P extends object, C extends object> = {
    type: "ChildAdded",
    parent: Parent<P>,
    child: Child<C>,
    noteData: NoteData,
    todoData?: TodoData,
    newChildLocation?: Location,
    location?: Location,
    source?: Source,
};

export type ChildAttached<P extends object, C extends object> = {
    type: "ChildAttached",
    parent: Parent<P>,
    child: Child<C>,
    location?: Location,
    source?: Source,
};

export type ChildDetached = {
    type: "ChildDetached",
    parent: Parent<Id>,
    child: Child<Id>,
};

export type LayerRemoved = {
    type: "LayerRemoved",
    parent: Parent<Id>,
    child: Child<Id>,
};

export type ChildMassDetached = {
    type: "ChildMassDetached",
    parent: Parent<Id>,
    child: Child<Id>,
};

export type DoneEdited = {
    type: "DoneEdited",
    id: Id,
    state: DoneState,
    source?: Source,
};

export type TitleEdited = {
    type: "TitleEdited",
    id: Id,
    title: Title,
};

export type DetailEdited = {
    type: "DetailEdited",
    id: Id,
    detail?: Detail,
};

export type CompletableEdited = {
    type: "CompletableEdited",
    id: Id,
    completable: Completable,
    source?: Source,
};

export type ChildrenReordered<P extends object, C extends object> = {
    type: "ChildrenReordered",
    parent: Parent<P>,
    children: Child<C>[],
};

export type ChildrenReorderedRelative = {
    type: "ChildrenReorderedRelative",
    parent: Parent<Id>,
    child: Child<Id>,
    position: RelativePosition,
    sibling: Child<Id>,
};

export type NewChildLocationUpdated = {
    type: "NewChildLocationUpdated",
    parent: Parent<Id>,
    location: Location,
};

export type GeoLocationFallbackPathSet = {
    type: "FallbackGeoLocationPathSet",
    path: string | null,
};

export type GeoLocationAdded = {
    type: "GeoLocationAdded",
    geoLocation: NamedGeoLocation,
};

export type GeoLocationEdited = {
    type: "GeoLocationEdited",
    name: string,
    geoLocation: NamedGeoLocation,
};

export type GeoLocationRemoved = {
    type: "GeoLocationRemoved",
    name: string,
};

export type ScheduledAdded = {
    type: "ScheduledAdded",
    scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>,
};

export type ScheduledEdited = {
    type: "ScheduledEdited",
    scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>,
};

export type ScheduledRemoved = {
    type: "ScheduledRemoved",
    id: ScheduledId,
};

export type ScheduleExecuted = {
    type: "ScheduleExecuted",
    date: ISO8601_DateTime,
};

export type NamedGeoLocation = Readonly<{
    name: string,
    latitude: number,
    longitude: number,
    path: string,
}>

export type AccountConfiguration = {
    "geoLocationFallbackPath"?: string,
    "lastScheduleExecution"?: ISO8601_DateTime,
};

export type ScheduleMutable = { [key: ScheduledId]: Scheduled<ScheduledId, readonly Lookup<Id>[]> };
export type Schedule = Readonly<ScheduleMutable>;

export type ScheduledId = UUID_V4;
export type Scheduled<I, P> = Readonly<{
    id: I,
    parents: P,
    title: Title,
    recurrence: readonly Recurrence[],
}>;

export type Recurrence
    = RecurrenceOnce
    | RecurrenceEveryNDays
    | RecurrenceEveryWeek
    | RecurrenceEveryMonthOnNthDayNumber
    | RecurrenceEveryMonthOnNthDayName
    | RecurrenceEveryYear
    ;

export type RecurrenceOnce = { type: "once", date: ISO8601_Date };
export type RecurrenceEveryNDays = { type: "every-n-days", n: number, ref: ISO8601_Date };
export type RecurrenceEveryWeek = { type: "every-week", day: DayName };
export type RecurrenceEveryMonthOnNthDayNumber = { type: "every-month-on-nth-day-number", day: DayNumber };
export type RecurrenceEveryMonthOnNthDayName = { type: "every-month-on-nth-day-name", week: number | "last", day: DayName };
export type RecurrenceEveryYear = { type: "every-year", date: ISO8601_DateWithoutYear };