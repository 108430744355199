import {mollari} from "./mollari/mollari";
import {parseUrlPath, toUiRepr, toUrlRepr, type UrlPath} from "../common/urlpath";
import {type SessionModel, initialModel, type Model, type Msg} from "./types";
import {DEFAULT_PATH, getWindowSize, msUntilMidnight} from "./util";
import {view} from "./view/view";
import {domainTx, handleCommands, update} from "./ui-state";
import "./global";
import "./data-load";
import "./get-item";
import "./search";
import "./keyboard-shortcuts";
import "./service-worker";
import "./error-reporting";
import {type RejectionReason, WSProtocol} from "./websocket/wsprotocol";
import {currentFragment, onFragmentChange, writeFragment} from "./fragment";
import 'bootstrap-icons/font/bootstrap-icons.css';
import {db, FancyTransaction} from "./db";
import {DEFAULT_LOCATION} from "../common/domain/data";
import {mutable} from "./mutable";
import {domainState} from "./domain-state";
import {Store} from "./store";
import {deepEqual} from "../common/deep-equal";
import {buildScheduledCommands, defaultLastScheduleExecution} from "../common/domain/scheduling";
import {maybeUndef} from "../common/util";
import {now, parseIsoDateTime} from "../common/datetime";
import {type DomainState} from "../common/domain/logic";
import {DAY_IN_MS} from "../common/constants";
import {SCHEDULING_SERVICE} from "./client-config";
import {restoreDomainFromServer} from "./view/util";
import {searchFactory} from "./search";

// NB. do this asap to ensure it's available for tests
if (!SCHEDULING_SERVICE) {
    // for the test env we want to control when the scheduling is executed, so hack it in
    (window as any).__executeSchedule = () => {
        console.log("__executeSchedule");
        void executeSchedule(domainState);
    };
}

let schedulingTaskRunning = false;

function startSchedulingTask(): void {
    if (!SCHEDULING_SERVICE || schedulingTaskRunning) return;

    void executeSchedule(domainState);
    setTimeout(() => {
        void executeSchedule(domainState);
        setInterval(() => {
            void executeSchedule(domainState);
        }, DAY_IN_MS);
    }, msUntilMidnight() + 60_000);

    schedulingTaskRunning = true;
}

function executeSchedule(domainState: DomainState<FancyTransaction>): Promise<void> {
    return domainTx(async tx => handleCommands(tx, await buildScheduledCommands(tx, domainState, now())));
}

void Promise.resolve().then(async () => {
    mutable.search = await searchFactory();

    const initialSessionModel = getInitialSessionModel();

    let path: UrlPath = parseUrlPath(currentFragment().substring(1));

    let model = initialModel(
        Store.getItem("queuedCommandGroups").length,
        getWindowSize(),
        path,
        initialSessionModel,
    );

    const redirectedPath = redirectPath(model, path);
    if (redirectedPath) {
        path = redirectedPath;
        model = {...model, path};
        // NB. calling writeFragment here before we've set up on onFragmentChange: this avoids loops
        writeFragment(`#${toUrlRepr(redirectedPath)}`);
    }

    const [send, getModel] = mollari(
        "app",
        model,
        deepEqual,
        view,
        (model_: Model, msg_: Msg) => {
            return update(model_, msg_);
        }
    );

    mutable.send = send;
    globalThis.appEnv.getModel = getModel;

    window.onresize = () => {
        mutable.send({
            type: "WindowSizeChanged",
            size: getWindowSize(),
        });
    };

    if (initialSessionModel.type === "NotYetLoggedIn") {
        mutable.wsConnection = new WSProtocol(
            initialSessionModel.session,
            restoreDomainFromServer,
            events => mutable.send({type: "Events", source: "SrcRemote", events: events}),
            () => {
                void db
                    .then(db => db.transaction("readonly", ["geoLocations", "schedule", "config"], tx => Promise.all([
                        domainState.getConfig(tx, "geoLocationFallbackPath"),
                        domainState.getConfig(tx, "lastScheduleExecution"),
                        domainState.getGeoLocations(tx),
                        domainState.getSchedule(tx),
                    ])))
                    .then(([fallbackPath, lastScheduleExecution, locations, schedule]) => {
                        mutable.send({type: "LoadGeo", fallbackPath: fallbackPath ?? null, locations});
                        mutable.send({type: "LoadSchedule", lastScheduleExecution: maybeUndef(lastScheduleExecution, defaultLastScheduleExecution(), parseIsoDateTime), schedule});
                        startSchedulingTask();
                    })
                    .then(() => {
                        mutable.send({type: "StateLoaded"});
                    });
            },
            restoreDomainFromServer,
            () => {
                mutable.send({type: "ProtocolLoggedIn", session: initialSessionModel.session});
                if (Store.getItem("clientState").type !== "NoState") {
                    startSchedulingTask();
                }
            },
            () => {
                mutable.send({type: "Disconnected"});

                if (Store.getItem("clientState").type !== "NoState") {
                    startSchedulingTask();
                }
            },
            domainState
        );

        if (process.env["NODE_ENV"] === "development" || window.navigator.onLine) {
            void mutable.wsConnection.start()
                .catch((reason: RejectionReason) => {
                    switch (reason) {
                        case "Bad login":
                            mutable.send({type: "ProtocolBadSession"});
                            writeFragment(`#${toUrlRepr({type: "Settings", component: "bad-session"})}`);
                            break;
                        case "Connection error":
                            break;
                        default:
                            const _: never = reason;
                    }
                });
        }
    }

    window.onfocus = () => {
        mutable.wsConnection?.reconnectIfNeeded();
    };

    onFragmentChange(async fragment => {
        let newPath = parseUrlPath(fragment.substring(1));

        const model_ = getModel();
        const redirectedPath = redirectPath(model_, newPath);
        if (redirectedPath) {
            writeFragment(`#${toUrlRepr(redirectedPath)}`);
            newPath = redirectedPath;
        }

        mutable.send({
            type: "PathChange",
            path: newPath,
        });
        setPageSubTitle(toUiRepr(newPath));

        path = newPath;
    });

    if (Store.getItem("clientState").type !== "NoState") {
        void db.then(db => db.transaction("readonly", ["geoLocations", "schedule", "config"], tx => Promise.all([
            domainState.getConfig(tx, "geoLocationFallbackPath"),
            domainState.getConfig(tx, "lastScheduleExecution"),
            domainState.getGeoLocations(tx),
            domainState.getSchedule(tx),
        ])))
            .then(async ([fallbackPath, lastScheduleExecution, locations, schedule]) => {
                mutable.send({type: "LoadGeo", fallbackPath: fallbackPath ?? null, locations});
                mutable.send({type: "LoadSchedule", lastScheduleExecution: maybeUndef(lastScheduleExecution, defaultLastScheduleExecution(), parseIsoDateTime), schedule});
            });

        db.then(db => db.transaction("readwrite", ["entrypoints", "items"], tx => domainState.getRoot(tx)
            .catch(async () => {
                await domainState.writeRoot(tx, {newChildLocation: DEFAULT_LOCATION, children: []});
            })
        ));
    }
}).catch(e =>
    console.error(e)
);

function getInitialSessionModel(): SessionModel {
    const session = Store.getItem("session");
    if (!session) {
        return {type: "NoSessionFound"};
    }

    return {type: "NotYetLoggedIn", session: session};
}

function redirectPath(model: Model, path: UrlPath): UrlPath | undefined {
    if (path.type === "Debug") {
        return undefined;
    }

    if (path.type === "Help") {
        return undefined;
    }

    if (path.type === "LoginError") {
        return undefined;
    }

    if (path.type === "Settings") {
        switch (path.component) {
            case "login":
            case "token-submission":
            case "bad-session":
                return undefined;
        }
    }

    if (model.session.type === "BadSessionError" && !(path.type === "Settings" && path.component === "bad-session")) {
        return {type: "Settings", component: "bad-session"};
    }

    if (model.session.type === "NoSessionFound") {
        return {type: "Settings", component: "login"};
    }

    if (path.type === "Empty") {
        return DEFAULT_PATH;
    }

    return undefined;
}

function setPageSubTitle(subTitle: string): void {
    document.title = `mapdone - ${subTitle}`;
}