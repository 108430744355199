import {clientDebug} from "./client-logging";

type Fragment = "" | `#${string}`;

export function currentFragment(): Fragment {
    return window.location.hash as Fragment;
}

export function onFragmentChange(handler: (fragment: Fragment) => void): void {
    window.onhashchange = () => handler(currentFragment());
}

export function writeFragment(fragment: Fragment) {
    clientDebug(() => `Writing fragment: ${fragment}`);
    window.location.href = fragment;
}
