import {clientDebug} from "../client-logging";
import {PATH_ONLINE_CHECK} from "../../common/config";

export class WebSocketApi {
    private readonly url: string;
    private readonly protocols?: string | string[];
    private readonly onClose: (ev: CloseEvent) => void;
    private readonly onOpen: (ev: Event) => Promise<void>;
    private readonly onMessage: (ev: MessageEvent<string>) => void;
    private readonly onError: (ev: Event) => void;

    private ws: WebSocket | undefined;
    private reconnect: any = null;

    constructor(url: string, onOpen: (ev: Event) => Promise<void>, onMessage: (ev: MessageEvent<string>) => Promise<void>, onClose: () => void, onError: (ev: Event) => void, onTerminalClose: (code: number, ev: CloseEvent) => Promise<void>, protocols?: string | string[]) {
        this.url = url;
        this.protocols = protocols;

        this.onOpen = (ev: Event) => {
            return onOpen(ev);
        };
        this.onMessage = (ev: MessageEvent<string>) => {
            void onMessage(ev);
        };
        this.onError = (ev) => {
            onError(ev);
        };

        // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
        const temporaryCloseCodes = [1000, 1001, 1006, 1011, 1012, 1013, 1014, 1015];
        this.onClose = (ev: CloseEvent) => {
            onClose();

            if (temporaryCloseCodes.indexOf(ev.code) === -1) {
                // terminal
                this.off();
                void onTerminalClose(ev.code, ev);
            }
        };

        this.reconnect = setInterval(() => this.connect(), 10_000);
        this.connect();
        onlineCallbacks.push(() => this.connect());
        offlineCallbacks.push(() => {
            this.ws?.close(1000, "Lost internet connection");
            onClose();
        });
    }

    public connect() {
        if (this.reconnect === null
            || (this.ws !== undefined && this.ws.readyState !== WebSocket.CLOSED)
        ) {
            return;
        }

        try {
            this.ws = new WebSocket(this.url, this.protocols);

            this.ws.addEventListener('open', (ev: Event) => void this.onOpen(ev));
            this.ws.addEventListener('close', (ev: CloseEvent) => this.onClose(ev));
            this.ws.addEventListener('error', (ev: Event) => this.onError(ev));
            this.ws.addEventListener('message',(ev: MessageEvent<string>) => this.onMessage(ev));
        } catch (e) {
            clientDebug(e);
        }
    }

    private off() {
        clearInterval(this.reconnect);
        this.reconnect = null;
    }

    public close(code?: number, reason?: string) {
        this.off();
        this.ws?.close(code, reason);
    }

    public send(data: string) {
        if (this.ws?.readyState !== WebSocket.OPEN) {
            throw new Error(`Cannot send message: ${data} WebSocket is not open`);
        }
        this.ws.send(data);
    }
}

async function fetchWithTimeout(input: string | URL | globalThis.Request, init?: RequestInit & {timeoutMs?: number}): Promise<Response> {
    const timeoutMs = init?.timeoutMs ?? 8000;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(`Timed out after ${timeoutMs}ms`), timeoutMs);

    const response = await fetch(input, {
        ...init,
        signal: controller.signal
    });
    clearTimeout(id);

    return response;
}


let online = false;
const onlineCallbacks: (() => void)[] = [];
const offlineCallbacks: (() => void)[] = [];

setInterval(() => {
    fetchWithTimeout(`${PATH_ONLINE_CHECK}?nonce=${Math.random()}`, {timeoutMs: 1_000})
        .then(() => {
            if (!online) {
                online = true;
                onlineCallbacks.forEach(cb => cb());
            }
        })
        .catch(() => {
            if (online) {
                online = false;
                offlineCallbacks.forEach(cb => cb());
            }
        })
}, 5*1_000);