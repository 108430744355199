const url = 'sw.js';

if ('serviceWorker' in navigator) {
    void navigator.serviceWorker.register(url);
}

export async function forceUpgradeButDontRefresh() {
    await caches.keys()
        .then(ks => Promise.all(ks.map(k => caches.delete(k))))
        .then(() => navigator.serviceWorker.register(url))
        .then(registration => registration.update());
}
