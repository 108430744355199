import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import React, {type ReactElement} from "react";
import {type HelpComponent, type HelpPath, helpToUiRepr, toUrlRepr} from "../../../common/urlpath";
import {keymap} from "../../keyboard-shortcuts";

export function helpPathLink(listComponent: HelpComponent): ReactElement {
    return (
        <ListGroupItem key={`tools-${listComponent}`}>
            <a key={"link"} href={`#${toUrlRepr({type: "Help", component: listComponent})}`}>
                {helpToUiRepr(listComponent)}
            </a>
        </ListGroupItem>
    );
}

export function helpView(path: HelpPath): ReactElement {
    const helpComponent = path.component;
    switch (helpComponent) {
        case undefined:
            return <ListGroup key={"help"} className={"link-list"}>
                <ListGroupItem>{helpPathLink("about")}</ListGroupItem>
                <ListGroupItem>{helpPathLink("keyboard")}</ListGroupItem>
            </ListGroup>;

        case "about":
            return <Card.Body key={"body"}>Version: {window.appEnv.VERSION}</Card.Body>;

        case "keyboard":
            return <Card>
                <Card.Body>
                    <Card.Title>{helpToUiRepr(helpComponent)}</Card.Title>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>Key combos</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(() => {
                            const rows: ReactElement[] = [];

                            keymap.forEach((v, k) => {
                                rows.push(<tr key={k.join(",")}>
                                    <td>{k.join(", ")}</td>
                                    <td>{v.desc}</td>
                                </tr>);
                            });

                            return rows;
                        })()}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>

        default:
            const _: never = helpComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(helpComponent)}`);
    }
}