import {BootstrapWindowSize, type GraphModel, type ItemGraph, type Model} from "../../types";
import {type GraphPath, toUrlRepr} from "../../../common/urlpath";
import {
    Breadcrumb,
    Button,
    Col, Dropdown,
    Form,
    InputGroup,
    OverlayTrigger,
    Row,
    Spinner, Tooltip
} from "react-bootstrap";
import {type Child, type Command, type Id, type IndexedItem, type Lookup, type Parent, ROOT, type Title} from "../../../common/domain/data";
import {breadcrumb, grandparents} from "./graph/breadcrumbs";
import {autoFocus, toIds, toLookup} from "../util";
import {updateDetail} from "./graph/util";
import {AddChildForm, detailOpt, detailPreviewCol, EditDetail} from "./graph/widgets";
import React, {type ReactElement, useEffect, useRef, useState} from "react";
import {domainTx, handleCommands} from "../../ui-state";
import {domainState} from "../../domain-state";
import {mutable} from "../../mutable";
import {getChildren} from "../../data-load";
import {isSomething} from "../../../common/util";
import {normalizeTitle} from "../../../common/domain/logic";
import {ROOT_TITLE, ROOT_TITLE_VERBOSE} from "../../global";
import {uuidv4} from "../../../common/uuid";
import {clearItemTitleState, ItemTitle, useItemTitleState} from "../../components/ItemTitle";
import {copy} from "../../util";
import {GraphChildren} from "./graph/children";
import {ModalWidget} from "../../components/ModalWidget";
import {Markdown} from "../../components/Markdown";

export const DOUBLE_CLICK_ADD_DETAIL_TEXT = "(double click here to add detail)";
export const DOUBLE_CLICK_EDIT_DETAIL_TEXT = "(double click here to edit detail)";

type GraphViewProps = { model: Model, graphModel: GraphModel, path: GraphPath };

export function GraphView({model, graphModel, path}: GraphViewProps): ReactElement {
    const addChildItemTitleState = useItemTitleState();
    const focus = graphModel.focus;

    return <div key={"graph"}>
        <Row key={"breadcrumbs"}>
            <Breadcrumb>
                {(() => {
                    const bIds: Id[] = [];
                    const breadcrumbs = [breadcrumb("#" + toUrlRepr({
                        ...path,
                        breadcrumbs: bIds
                    }), "root", ROOT_TITLE)];
                    for (const b of path.breadcrumbs.map(b => mutable.search.get(b)).filter(isSomething)) {
                        if (b.id === ROOT) continue;

                        bIds.push(b.id);
                        const href = "#" + toUrlRepr({
                            ...path,
                            breadcrumbs: bIds
                        });
                        breadcrumbs.push(breadcrumb(href, `${b.id}-${b.noteData.title}-${b.todoData?.doneState}`, b.noteData.title, b.todoData));
                    }
                    return breadcrumbs;
                })()}
            </Breadcrumb>
        </Row>
        {grandparents(path.breadcrumbs, toIds(focus.parents))}
        {modal(model.windowSize, focus, model.graph)}
        <Row key={"body"} className={"focus"}>
            {focus.id === ROOT
                ? <FocusRootTitle rootModel={focus}/>
                : <>
                    <FocusTitle
                        key={`title-${focus.id}-${focus.noteData.title}-${focus.todoData?.doneState}}`}
                        item={focus} windowSize={model.windowSize}/>
                    <FocusDetail key={`detail-${focus.id}-${focus.noteData.detail}`}
                                 item={focus} windowSize={model.windowSize}/>
                </>
            }
        </Row>
        <Row
            key={"children"}
            className={`children`}
        >
            <Col>
                <center style={{marginTop: "1em", marginBottom: "1em"}}>
                    <Button variant={"outline-secondary"} disabled={graphModel.childDepth === 1} onClick={() => mutable.send({type: "SetChildDepth", depth: graphModel.childDepth - 1})}>↙</Button>
                    <span style={{marginLeft: "0.5em", marginRight: "0.5em"}}>{graphModel.childDepth}</span>
                    <Button variant={"outline-secondary"} onClick={() => mutable.send({type: "SetChildDepth", depth: graphModel.childDepth + 1})}>↗</Button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button variant={"primary"} className={"next"} href={`#${toUrlRepr({
                        type: "Tools",
                        component: "next",
                        id: toLookup(focus) ?? undefined,
                    })}`}>
                        <i
                            className={`bi bi-compass-fill`}
                            title={"Next"}
                        /> Next
                    </Button>
                </center>
                <AddChildForm
                    key={`add-child-${focus.id}`}
                    model={model}
                    focus={focus}
                    addChildItemTitleState={addChildItemTitleState}
                    path={path}
                    newChildLocation={focus.newChildLocation}/>
                <GraphChildren focus={focus} graphPath={path} windowSize={model.windowSize} />
                <center style={{marginTop: "1em", marginBottom: "1em"}}>
                    Copy list:

                    &nbsp;

                    <Button variant={"primary"} onClick={ev => {
                        ev.preventDefault();
                        void copy(
                            todosAsText(mutable.search.get.bind(mutable.search), toIds(focus.children)).trimEnd(),
                            {message: "TODOs copied to clipboard"}
                        );
                    }}><i
                        className={`bi bi-link-45deg`}
                        title={"Copy TODOs to clipboard"}
                    /> TODOs</Button>

                    &nbsp;

                    <Button variant={"secondary"} onClick={ev => {
                        ev.preventDefault();

                        void copy(
                            listAsText(mutable.search.get.bind(mutable.search), toIds(focus.children)).trimEnd(),
                            {message: "List copied to clipboard"}
                        );
                    }}><i
                        className={`bi bi-link-45deg`}
                        title={"Copy list to clipboard"}
                    /> All</Button>
                </center>
            </Col>
        </Row>
    </div>;
}

function modal(windowSize: BootstrapWindowSize, focus: ItemGraph, graphModel: GraphModel): ReactElement | undefined {
    const graphModal = graphModel.modal;
    if (graphModal === null) {
        return undefined;
    }

    switch (graphModal.type) {
        case "CopyChildModal":
            const isInvalid = graphModal.targetTitle === "" || graphModal.targetParent.childTitles.includes(normalizeTitle(graphModal.targetTitle));
            return <ModalWidget title={"Copy to..."} onHide={() => mutable.send({type: "SetGraphModal", modal: null})}>
                <Form
                    name={"copy-form"}
                    onSubmit={ev => {
                        ev.preventDefault();
                        if (isInvalid || !ev.currentTarget.checkValidity()) return;

                        void domainTx(async tx => {
                            const commands: Command[] = [];
                            const queue: [Id, Lookup<Id>][] = [[graphModal.sourceId, graphModal.targetParent.lookup]];

                            let rootOfSubtree = true; // a couple of things are different for the root
                            while (queue.length > 0) {
                                const [fromId, toParent] = queue.shift() as [Id, Lookup<Id>];

                                const newChildId = uuidv4() as Child<Id>;
                                const fromItem = await domainState.getItemThrows(tx, "item", fromId);

                                const noteData = rootOfSubtree
                                    ? {...fromItem.noteData, title: graphModal.targetTitle}
                                    : fromItem.noteData;

                                const location = rootOfSubtree
                                    ? (await (toParent === ROOT ? domainState.getRoot(tx) : domainState.getItemThrows(tx, "parent", toParent as Id))).newChildLocation
                                    : "bottom"; // NB. we're always copying to the bottom as that's the order we're about to iterate over fromItem.children

                                rootOfSubtree = false;

                                commands.push({
                                    type: "AddChild",
                                    parent: toParent,
                                    child: newChildId,
                                    noteData,
                                    ...(fromItem.todoData === undefined
                                            ? {}
                                            : {todoData: {doneState: "todo"}}
                                    ),
                                    newChildLocation: fromItem.newChildLocation,
                                    location,
                                });

                                queue.push(...fromItem.children.map(c => ([c, newChildId] as [Id, Lookup<Id>])));
                            }

                            mutable.send({type: "SetGraphModal", modal: {...graphModal, working: true}});

                            await handleCommands(tx, commands);

                            mutable.send({type: "SetGraphModal", modal: null});
                        });
                    }}
                >
                    <fieldset disabled={graphModal.working}>
                        <Row>
                            <InputGroup>
                                <ItemTitle
                                    initialState={{
                                        defaultHighlight: 0,
                                        input: graphModal.targetParent.title,
                                        chosenItem: mutable.search.get(graphModal.targetParent.lookup),
                                    }}
                                    placeholder={"into"}
                                    required
                                    componentStyle={{width: "100%"}}
                                    autoFocus={autoFocus(windowSize)}
                                    onItemChosen={chosen => {
                                        void domainTx(async tx => {
                                            if (chosen === null) return;

                                            let title: Title, childIds: readonly Child<Id>[];

                                            if (chosen.id === ROOT) {
                                                const root = await domainState.getRoot(tx);
                                                title = ROOT_TITLE_VERBOSE;
                                                childIds = root.children;
                                            } else {
                                                const item = await domainState.getItemThrows(tx, "item", chosen.id);
                                                title = item.noteData.title;
                                                childIds = item.children;
                                            }

                                            mutable.send({
                                                type: "SetGraphModal",
                                                modal: {
                                                    ...graphModal,
                                                    targetParent: {
                                                        lookup: chosen.id,
                                                        title,
                                                        childTitles: (await getChildren(tx, childIds))
                                                            .filter(isSomething)
                                                            .map(c => c.noteData.title),
                                                    },
                                                    working: false,
                                                }
                                            });
                                        });
                                    }}
                                />
                                <Form.Control.Feedback type={"invalid"}>Must provide target</Form.Control.Feedback>
                            </InputGroup>
                        </Row>
                        <Row>
                            <InputGroup hasValidation>
                                <Form.Control
                                    name={"item-title"}
                                    placeholder={"with new title"}
                                    value={graphModal.targetTitle}
                                    required
                                    isInvalid={isInvalid}
                                    onChange={ev => {
                                        mutable.send({
                                            type: "SetGraphModal",
                                            modal: {
                                                ...graphModal,
                                                targetTitle: ev.target.value as Title,
                                            }
                                        });
                                    }}
                                />
                                <Form.Control.Feedback type={"invalid"}>Must provide unique name</Form.Control.Feedback>
                            </InputGroup>
                        </Row>
                        <Row>
                            <Button type={"submit"}>{graphModal.working
                                ? <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Copying...</span>
                                </Spinner>
                                : "Copy"
                            }</Button>
                        </Row>
                    </fieldset>
                </Form>
            </ModalWidget>;

        case "MoveChildModal":
            return <ModalWidget title={"Move to..."} onHide={() => mutable.send({type: "SetGraphModal", modal: null})}>
                <ItemTitle
                    initialState={{defaultHighlight: 0}}
                    placeholder={"title"}
                    componentStyle={{width: "100%"}}
                    autoFocus={autoFocus(windowSize)}
                    idBlockList={graphModal.idBlockList}
                    onItemChosen={chosen => {
                        if (chosen === null) return;

                        void domainTx(tx => handleCommands(tx, [{
                            type: "MoveChild",
                            fromParent: focus.id as Parent<Id>,
                            toParent: chosen.id as Parent<Id>,
                            child: graphModal.id as Child<Id>,
                        }]));

                        mutable.send({type: "SetGraphModal", modal: null});
                    }}
                />
            </ModalWidget>;

        case "TagChildModal":
            return <TagModal windowSize={windowSize} idBlockList={graphModal.idBlockList}
                             from={{type: "child", child: graphModal.id as Child<Id>}}
                             onHide={() => mutable.send({type: "SetGraphModal", modal: null})}/>;

        default: {
            // noinspection JSUnusedLocalSymbols
            const _: never = graphModal;
            throw new Error(`Unexpected object: ${JSON.stringify(graphModal)}`);
        }
    }
}

type From
    = { type: "parent", parent: Parent<Id> }
    | { type: "child", child: Child<Id> }
type TagModalProps = {
    from: From,
    idBlockList: readonly Lookup<Id>[],
    windowSize: BootstrapWindowSize,
    onHide: () => void,
};

function TagModal({from, idBlockList, windowSize, onHide}: TagModalProps): ReactElement {
    return <ModalWidget title={"Tag with..."} onHide={onHide}>
        <ItemTitle
            initialState={{defaultHighlight: 0}}
            placeholder={"title"}
            componentStyle={{width: "100%"}}
            autoFocus={autoFocus(windowSize)}
            idBlockList={idBlockList}
            onItemChosen={(chosen, state) => {
                if (chosen === null) return;

                void domainTx(tx => handleCommands(tx, [{
                    type: "AttachChild",
                    parent: from.type === "parent" ? from.parent : chosen.id as Parent<Id>,
                    child: from.type === "child" ? from.child : chosen.id as Child<Id>,
                }]));

                clearItemTitleState(state);
                onHide();
            }}
        />
    </ModalWidget>;
}

type FocusTitleProps = {
    item: IndexedItem<any, any, Lookup<Id>>,
    windowSize: BootstrapWindowSize,
};

function FocusTitle({item, windowSize}: FocusTitleProps): ReactElement {
    const [editing, setEditing] = useState(false);
    const [title, setTitle] = useState(item.noteData.title as string);
    const [tagging, setTagging] = useState(false);
    const inputGroupRef = useRef<HTMLElement>(null);
    const inputGroupOffsetHeightRef = useRef(-1);
    useEffect(() => {
        if (inputGroupRef.current) {
            inputGroupOffsetHeightRef.current = inputGroupRef.current.offsetHeight;
        }
    }, [inputGroupRef]);

    return <>
        <InputGroup>
            {item.todoData
                ? <InputGroup.Checkbox className={"done"} type={"checkbox"} checked={item.todoData.doneState === "done"}
                                       onChange={() => {
                                           const id = item.id;
                                           if (id === ROOT) return;

                                           const todoData = item.todoData;
                                           if (todoData === undefined) return;

                                           void domainTx(tx => handleCommands(tx, [{
                                               type: "EditDone",
                                               item: id,
                                               from: todoData.doneState,
                                               to: todoData.doneState === "done" ? "todo" : "done",
                                           }]));
                                       }}/>
                : undefined
            }
            <Dropdown>
                <Dropdown.Toggle className={"focus-extra"} variant={"outline-secondary"}/>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => domainTx(tx => handleCommands(tx, [{
                        type: "UpdateNewChildLocation",
                        parent: item.id as Parent<Id>,
                        location: item.newChildLocation === "top" ? "bottom" : "top"
                    }]))}>Add new items to the {item.newChildLocation === "top" ? "bottom" : "top"} of the
                        list</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        const id = item.id;
                        if (id === ROOT) return;

                        return domainTx(tx => handleCommands(tx, [{
                            type: "EditCompletable",
                            item: id,
                            from: item.todoData ? "is-completable" : "is-not-completable",
                            to: item.todoData ? "is-not-completable" : "is-completable"
                        }]));
                    }}>Mark item {item.todoData ? "not completable" : "completable"}</Dropdown.Item>
                    <Dropdown.Item onClick={() => setTagging(true)}>Tag</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {editing
                ? <Form onSubmit={e => {
                    e.preventDefault();
                    editTitle();
                }}><Form.Control style={{height: `${inputGroupOffsetHeightRef.current}px`}} autoFocus={true}
                                 value={title} onChange={e => setTitle(e.target.value)}
                                 onBlur={() => editTitle()}/></Form>
                : <InputGroup.Text ref={inputGroupRef}>
                    <OverlayTrigger
                        overlay={<Tooltip>Double click to edit</Tooltip>}
                        placement={"bottom"}
                    >
                        <h3 className={"title"}
                            style={{marginTop: "0.4em"}}
                            onDoubleClick={ev => {
                                ev.preventDefault();
                                setEditing(true);
                            }}
                        >{title}</h3>
                    </OverlayTrigger>
                </InputGroup.Text>
            }
        </InputGroup>
        {tagging
            ? <TagModal from={{type: "child", child: item.id as Child<Id>}} idBlockList={[item.id as Lookup<Id>]}
                        windowSize={windowSize} onHide={() => setTagging(false)}/>
            : undefined
        }
    </>;

    function editTitle() {
        const id = item.id;
        if (id === ROOT) return;
        if (title.length === 0) return;
        setEditing(false);
        void domainTx(tx => handleCommands(tx, [{
            type: "EditTitle",
            item: id,
            from: item.noteData.title,
            to: title as Title,
        }]));
    }
}

type FocusRootTitleProps = {
    rootModel: IndexedItem<any, any, Lookup<Id>>,
};

function FocusRootTitle({rootModel}: FocusRootTitleProps): ReactElement {
    return <InputGroup style={{marginBottom: "1em"}}>
        <Dropdown>
            <Dropdown.Toggle className={"focus-extra"} variant={"outline-secondary"}/>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => domainTx(tx => handleCommands(tx, [{
                    type: "UpdateNewChildLocation",
                    parent: ROOT,
                    location: rootModel.newChildLocation === "top" ? "bottom" : "top"
                }]))}>Add new items to the {rootModel.newChildLocation === "top" ? "bottom" : "top"} of the
                    list</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <InputGroup.Text><h3 style={{marginTop: "0.4em"}}> {ROOT_TITLE}</h3></InputGroup.Text>
    </InputGroup>;
}

type DetailCardProps = {
    item: IndexedItem<any, any, Lookup<Id>>,
    windowSize: BootstrapWindowSize,
};

function FocusDetail({item, windowSize}: DetailCardProps): ReactElement {
    const [editing, setEditing] = useState(false);
    const [detail, setDetail] = useState<string>(item.noteData.detail ?? "");

    if (editing) {
        return <div
            key={`detail-${item.id}`}
            style={{marginTop: "1em", marginBottom: "1em"}}
        >
            <Row>
                <Col key={"edit"} className={"col-12 col-md-6"} style={{marginBottom: "1em"}}>
                    <EditDetail
                        name={"detail"}
                        value={detail}
                        onCtrlEnter={ev => {
                            ev.preventDefault();
                            if (document.activeElement instanceof HTMLElement) {
                                document.activeElement.blur();
                            }
                        }}
                        autoFocus={autoFocus(windowSize)}
                        onChange={ev => setDetail(ev.target.value)}
                        onBlur={ev => {
                            setEditing(false);
                            void domainTx(tx => updateDetail(tx, ev.target.value, item));
                        }}
                    />
                    {detailOpt()}
                </Col>
                {detailPreviewCol(detail)}
            </Row>
        </div>;
    }

    return <div
        key={`detail-${item.id}`}
        style={{marginTop: "1em", marginBottom: "1em"}}
    >
        <div
            onDoubleClick={ev => {
                ev.preventDefault();
                setEditing(true);
            }}
        >
            {detail.length === 0
                ? <center><span className={"detail-status"}>{DOUBLE_CLICK_ADD_DETAIL_TEXT}</span></center>
                : <>
                    <Markdown md={detail} />
                    <center><span className={"detail-status"}>{DOUBLE_CLICK_EDIT_DETAIL_TEXT}</span></center>
                </>
            }
        </div>
    </div>;
}

type GetItem = (id: Id) => ItemGraph<Id> | undefined;

function todosAsText(getItem: GetItem, ids: readonly Id[], indent = ""): string {
    return ids
        .map(id => getItem(id))
        .filter(isSomething)
        .filter(c => c.todoData?.doneState === "todo")
        .map(c => `${indent}- ${c.noteData.title}\n${todosAsText(getItem, toIds(c.children), indent + "  ")}`)
        .join("")
}

function listAsText(getItem: GetItem, ids: readonly Id[], indent = ""): string {
    return ids
        .map(id => getItem(id))
        .filter(isSomething)
        .map(c => `${indent}${c.todoData?.doneState ? c.todoData.doneState === "done" ? "x" : "-" : "~"} ${c.noteData.title}\n${listAsText(getItem, toIds(c.children), indent + "  ")}`)
        .join("")
}
